import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ASSIGNMENT_TAB } from 'utils/Constants';
import { RootState } from 'redux/store';

export type Tool = 'roi' | 'cell' | 'polygon' | 'polygon-select' | 'polygon-split' | 'measurement' | 'comment' | 'wsi-region';
export type AnnotationFilters = { class_id: Array<string> | null, is_reviewed: Array<boolean> | null };

interface ViewerOptions {
  isAnnotatingEnabled: boolean;
  isUserSelecting: boolean;
  isAnnotationsVisible: boolean;
  activeAnnotationClassification: string | null;
  jumpToLocation: string | null;
  jumpToCoordinate: [number, number] | null;
  scrollToAnnotationId: string | null;
  mouseCoordinates: string;
  isHotspotDetailModalVisible: boolean;
  annotationFilters: AnnotationFilters | null;
  annotationReviewFilters: Array<boolean>;
  activeReviewAnnotation: string | null;
  activeReviewRowIndex: number;
  isSidebarCollapsed: boolean;
  isMeasurementToolActive: boolean;
  activeAssignmentTab: string;
  activeAssignmentItemId: string | null;
  isTileLoadProgressBarActive: boolean;
  tileLoadProgressLoading: number;
  tileLoadProgressLoaded: number;
  showWSIRegion: boolean;
  imageZoomButtonLevel: number | null;
  imageZoomLevel: number;
  isViewerLocked: boolean;
  tools: {
    available: Tool[];
    active: Tool | null;
  }
}

const initialState: ViewerOptions = {
  isAnnotatingEnabled: false,
  isUserSelecting: false,
  isAnnotationsVisible: true,
  activeAnnotationClassification: null,
  jumpToLocation: null,
  jumpToCoordinate: null,
  scrollToAnnotationId: null,
  // TODO: rather use object here
  mouseCoordinates: 'x: 0, y: 0',
  isHotspotDetailModalVisible: false,
  annotationFilters: null,
  annotationReviewFilters: [],
  activeReviewAnnotation: null,
  activeReviewRowIndex: -1,
  isSidebarCollapsed: false,
  isMeasurementToolActive: false,
  activeAssignmentTab: ASSIGNMENT_TAB.JOBS.key,
  activeAssignmentItemId: null,
  isTileLoadProgressBarActive: false,
  tileLoadProgressLoading: 0,
  tileLoadProgressLoaded: 0,
  showWSIRegion: false,
  imageZoomButtonLevel: null,
  imageZoomLevel: 0,
  isViewerLocked: false,
  tools: {
    available: [],
    active: null
  }
};

export const viewerOptions = createSlice({
  name: 'viewerOptions',
  initialState,
  reducers: {
    setIsAnnotatingEnabled: (state, action) => {
      state.isAnnotatingEnabled = action.payload;
    },
    setIsAnnotationsVisible: (state, action) => {
      state.isAnnotationsVisible = action.payload;
    },
    setIsHotspotDetailModalVisible: (state, action) => {
      state.isHotspotDetailModalVisible = action.payload;
    },
    setActiveAnnotationClassification: (state, action) => {
      state.activeAnnotationClassification = action.payload;
    },
    setJumpToLocation: (state, action: PayloadAction<string | null>) => {
      state.jumpToLocation = action.payload;
    },
    setJumpToCoordinate: (state, action) => {
      state.jumpToCoordinate = action.payload;
    },
    setScrollToAnnotationId: (state, action) => {
      state.scrollToAnnotationId = action.payload;
    },
    setActiveReviewAnnotation: (state, action) => {
      state.activeReviewAnnotation = action.payload;
    },
    setActiveReviewRowIndex: (state, action) => {
      state.activeReviewRowIndex = action.payload;
    },
    setMouseCoordinates: (state, action) => {
      state.mouseCoordinates = action.payload;
    },
    setAnnotationFilters: (state, action: PayloadAction<AnnotationFilters>) => {
      state.annotationFilters = action.payload;
    },
    resetAnnotationFilters: (state) => {
      state.annotationFilters = null;
    },
    setAnnotationReviewFilters: (state, action) => {
      state.annotationReviewFilters = action.payload;
    },
    setIsSidebarCollapsed: (state, action) => {
      state.isSidebarCollapsed = action.payload;
    },
    setAvailableTools: (state, action: PayloadAction<Array<Tool>>) => {
      state.tools.available = action.payload;
    },
    toggleTool: (state, action: PayloadAction<Tool | null>) => {
      if (state.tools.active === action.payload) {
        state.tools.active = null;
      } else {
        state.tools.active = action.payload
      }
    },
    activateDefaultTool: (state) => {
      // For now, the first tool in the list is always the default tool
      if (state.tools.available.length > 0) {
        state.tools.active = state.tools.available[0]
      } else {
        state.tools.active = null;
      }
    },
    setIsMeasurementToolActive: (state, action) => {
      state.isMeasurementToolActive = action.payload;
    },
    clearViewerOptions: () => initialState,
    setActiveAssignmentItemId: (state, action) => {
      state.activeAssignmentItemId = action.payload;
    },
    setActiveAssignmentTab: (state, action) => {
      state.activeAssignmentTab = action.payload;
    },
    setIsTileLoadProgressBarActive: (state, action) => {
      state.isTileLoadProgressBarActive = action.payload;

      if (!action.payload) {
        state.tileLoadProgressLoading = 0;
        state.tileLoadProgressLoaded = 0;
      }
    },
    incrementTileLoadProgressLoading: state => {
      state.tileLoadProgressLoading += 1;
    },
    incrementTileLoadProgressLoaded: state => {
      state.tileLoadProgressLoaded += 1;
    },
    setShowWSIRegion: (state, action) => {
      state.showWSIRegion = action.payload;
    },
    setImageZoomButtonLevel: (state, action) => {
      state.imageZoomButtonLevel = action.payload;
    },
    clearImageImageZoomButtonLevel: state => {
      state.imageZoomButtonLevel = null;
    },
    setImageZoomLevel: (state, action) => {
      state.imageZoomLevel = action.payload;
    },
    setIsViewerLocked: (state, action) => {
      state.isViewerLocked = action.payload;
    },
  },
});

export const {
  setIsAnnotatingEnabled,
  setIsAnnotationsVisible,
  setIsHotspotDetailModalVisible,
  setActiveAnnotationClassification,
  setJumpToLocation,
  setJumpToCoordinate,
  setScrollToAnnotationId,
  setActiveReviewAnnotation,
  setActiveReviewRowIndex,
  setMouseCoordinates,
  setAnnotationFilters,
  resetAnnotationFilters,
  setAnnotationReviewFilters,
  setIsSidebarCollapsed,
  setAvailableTools,
  toggleTool,
  activateDefaultTool,
  setIsMeasurementToolActive,
  clearViewerOptions,
  setActiveAssignmentTab,
  setActiveAssignmentItemId,
  setIsTileLoadProgressBarActive,
  incrementTileLoadProgressLoading,
  incrementTileLoadProgressLoaded,
  setShowWSIRegion,
  setImageZoomButtonLevel,
  clearImageImageZoomButtonLevel,
  setImageZoomLevel,
  setIsViewerLocked,
} = viewerOptions.actions;

export const selectIsAnnotatingEnabled = (state: RootState) => state.viewerOptions.isAnnotatingEnabled;
export const selectActiveReviewAnnotation = (state: RootState) => state.viewerOptions.activeReviewAnnotation;
export const selectActiveReviewRowIndex = (state: RootState) => state.viewerOptions.activeReviewRowIndex;
export const selectAnnotationFilters = (state: RootState) => state.viewerOptions.annotationFilters;
export const selectAnnotationReviewFilters = (state: RootState) => state.viewerOptions.annotationReviewFilters;
export const selectScrollToAnnotationId = (state: RootState) => state.viewerOptions.scrollToAnnotationId;
export const selectActiveAnnotationClassification = (state: RootState) =>
  state.viewerOptions.activeAnnotationClassification;
export const selectActiveTool = (state: RootState) => state.viewerOptions.tools.active;
export const selectAvaliableTools = (state: RootState) => state.viewerOptions.tools.available;
export const selectIsMeasurementToolActive = (state: RootState) => state.viewerOptions.isMeasurementToolActive;
export const selectIsSidebarCollapsed = (state: RootState) => state.viewerOptions.isSidebarCollapsed;
export const selectIsAnnotationsVisible = (state: RootState) => state.viewerOptions.isAnnotationsVisible;
export const selectIsHotspotDetailModalVisible = (state: RootState) => state.viewerOptions.isHotspotDetailModalVisible;
export const selectJumpToLocation = (state: RootState) => state.viewerOptions.jumpToLocation;
export const selectJumpToCoordinate = (state: RootState) => state.viewerOptions.jumpToCoordinate;
export const selectMouseCoordinates = (state: RootState) => state.viewerOptions.mouseCoordinates;
export const selectActiveAssignmentTab = (state: RootState) => state.viewerOptions.activeAssignmentTab;
export const selectIsTileLoadProgressBarActive = (state: RootState) => state.viewerOptions.isTileLoadProgressBarActive;
export const selectTileLoadProgressLoading = (state: RootState) => state.viewerOptions.tileLoadProgressLoading;
export const selectTileLoadProgressLoaded = (state: RootState) => state.viewerOptions.tileLoadProgressLoaded;
export const selectShowWSIRegion = (state: RootState) => state.viewerOptions.showWSIRegion;
export const selectImageZoomButtonLevel = (state: RootState) => state.viewerOptions.imageZoomButtonLevel;
export const selectImageZoomLevel = (state: RootState) => state.viewerOptions.imageZoomLevel;
export const selectIsViewerLocked = (state: RootState) => state.viewerOptions.isViewerLocked;

export default viewerOptions.reducer;
