import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { OL_LAYER_NAME } from 'utils/Constants';
import VectorSource from 'ol/source/Vector';
import { Geometry } from 'ol/geom';
import { CommentAnnotationDict } from 'redux/slices/imageServerApi';

interface CommentLayerProps {
  source: VectorSource<Feature<Geometry>>;
  commentAnnotations: Array<CommentAnnotationDict>;
  visible?: boolean;
};

const CommentLayer: React.FC<CommentLayerProps> = ({ source, commentAnnotations, visible = true }) => {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  let commentLayerRef = useRef<VectorLayer<VectorSource<Feature<Geometry>>> | null>(null);

  const initOLLayer = () => {
    const hotspotLayer = new VectorLayer({
      className: 'comment-layer',
      properties: { name: OL_LAYER_NAME.COMMENTS },
      source,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: new Style({
        stroke: new Stroke({
          color: '#7ae1bf',
          width: 3,
          lineDash: [5, 10]
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      }),
    });
    hotspotLayer.setZIndex(1);
    return hotspotLayer;
  };

  useEffect(() => {
    if (!map) return;
    commentLayerRef.current = initOLLayer();
    map.addLayer(commentLayerRef.current);

    return () => {
      if (map && commentLayerRef.current) {
        map.removeLayer(commentLayerRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source]);

  useEffect(() => {
    if (!map || !commentLayerRef.current) return;
    commentLayerRef.current.setVisible(visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (!source || !commentLayerRef.current) return;
    commentLayerRef.current.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentAnnotations]);

  useEffect(() => {
    if (!source) return;
    source.clear();
  }, [source]);

  useEffect(() => {
    if (!commentAnnotations || commentAnnotations.length === 0) return;

    source.clear();
    commentAnnotations.forEach(comment => {
      if (comment.coordinates) {
        let newROI = new Feature({});
        let coordinates: Array<Array<number>> = [];

        comment.coordinates.forEach(coordinate => {
          coordinates.push([coordinate[0], -coordinate[1]]);
        });

        newROI.setGeometry(new Polygon([coordinates]));
        newROI.setId(comment.id);

        source.addFeatures([newROI]);
      }

      source.changed();
    });
  }, [commentAnnotations]);

  return null
}

export default CommentLayer;
