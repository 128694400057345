import React from 'react';
import ReactFlow, { Node, Edge, Background, BackgroundVariant } from 'reactflow';
import dagre from 'dagre';
import { Modal, Spin } from 'antd';
import { JobTreeSchema, useGetJobTreeQuery } from 'redux/slices/imageServerApi';

import 'reactflow/dist/style.css';
import './style.css';

interface JobTreeModalProps {
    rootJobId: string | null;
    onCancel: () => void;
}

const getLayoutedElements = (nodes: Node[], edges: Edge[], direction = 'LR') => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 172;
    const nodeHeight = 100;

    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

const renderNodeContent = (node: JobTreeSchema) => (
    <a href={`/jobs/${node.id}`}>
        <h4>{node.task_name}</h4>
        <p>{node.username}</p>
        <p>Branch: {node.branch_id}</p>
    </a>
);

const transformJobTreeToNodesAndEdges = (jobTree?: JobTreeSchema) => {
    if (!jobTree) return { nodes: [], edges: [] };

    let nodes: Node[] = [];
    let edges: Edge[] = [];

    const traverseTree = (node: JobTreeSchema) => {
        const nodeColor = node.is_completed ? '#d4f8d4' : '#fff8b3'; // Lighter green and yellow

        nodes.push({
            id: node.id,
            data: { label: renderNodeContent(node) },
            position: { x: 0, y: 0 },
            style: { backgroundColor: nodeColor }
        });

        node.next_jobs.forEach((child) => {
            edges.push({
                id: `${node.id}-${child.id}`,
                source: node.id,
                target: child.id,
                animated: true,
                type: 'smoothstep'
            });
            traverseTree(child);
        });
    };

    traverseTree(jobTree);

    ({ nodes, edges } = getLayoutedElements(nodes, edges))

    return { nodes, edges };
};

const Legend = () => (
    <div style={{ display: 'flex', marginBottom: '10px' }}>
        <div style={{ marginRight: '20px' }}>
            <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#d4f8d4', marginRight: '5px' }}></span>
            Completed
        </div>
        <div>
            <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: '#fff8b3', marginRight: '5px' }}></span>
            Not Completed
        </div>
    </div>
);

const JobTreeModal: React.FC<JobTreeModalProps> = ({ rootJobId, onCancel }) => {
    const { data: jobTree, isFetching } = useGetJobTreeQuery({ id: rootJobId }, { skip: !rootJobId })
    const { nodes, edges } = transformJobTreeToNodesAndEdges(jobTree);

    return (
        <Modal
            title="Annotation Pipeline"
            open={!!rootJobId}
            onCancel={onCancel}
            footer={null}
            width="80%"
        >
            <Spin spinning={isFetching}>
                <div style={{ width: '100%', height: '800px' }}>
                    <Legend />
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        fitView
                        maxZoom={1}
                        panOnDrag={true}
                    >
                        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
                    </ReactFlow>
                </div>
            </Spin>
        </Modal>
    );
};

export default JobTreeModal;
