import { emptyApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    imageServerAppsUsersApiUserAuthToken: build.mutation<
      ImageServerAppsUsersApiUserAuthTokenApiResponse,
      ImageServerAppsUsersApiUserAuthTokenApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/user/auth/token`,
        method: "POST",
        body: queryArg.userAuthIn,
      }),
    }),
    getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/user/`,
        params: {
          group: queryArg.group,
        },
      }),
    }),
    getGroup: build.query<GetGroupApiResponse, GetGroupApiArg>({
      query: (queryArg) => ({ url: `/api/v2/user/groups/${queryArg.groupId}` }),
    }),
    getGroups: build.query<GetGroupsApiResponse, GetGroupsApiArg>({
      query: () => ({ url: `/api/v2/user/groups/` }),
    }),
    getTasks: build.query<GetTasksApiResponse, GetTasksApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/tasks/`,
        params: {
          workflow: queryArg.workflow,
        },
      }),
    }),
    getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/tasks/${queryArg.name}`,
      }),
    }),
    getWorkflows: build.query<GetWorkflowsApiResponse, GetWorkflowsApiArg>({
      query: () => ({ url: `/api/v2/annotations/workflows/` }),
    }),
    getWorkflow: build.query<GetWorkflowApiResponse, GetWorkflowApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/workflows/${queryArg.name}`,
      }),
    }),
    getJob: build.query<GetJobApiResponse, GetJobApiArg>({
      query: (queryArg) => ({ url: `/api/v2/annotations/jobs/${queryArg.id}` }),
    }),
    getJobs: build.query<GetJobsApiResponse, GetJobsApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/`,
        params: {
          workflow: queryArg.workflow,
          classification_scenario: queryArg.classificationScenario,
          lab: queryArg.lab,
          scanner: queryArg.scanner,
          staining: queryArg.staining,
          staining_detail: queryArg.stainingDetail,
          organ: queryArg.organ,
          username: queryArg.username,
          previous_user: queryArg.previousUser,
          task: queryArg.task,
          roi_type: queryArg.roiType,
          reviewed: queryArg.reviewed,
          pathologist: queryArg.pathologist,
          annotator: queryArg.annotator,
          qa_quality: queryArg.qaQuality,
          backlog_id: queryArg.backlogId,
          dataset_partition: queryArg.datasetPartition,
          exclude: queryArg.exclude,
          is_completed: queryArg.isCompleted,
          page: queryArg.page,
        },
      }),
    }),
    getOutputJobs: build.query<GetOutputJobsApiResponse, GetOutputJobsApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/outputs/`,
        params: {
          workflow: queryArg.workflow,
          classification_scenario: queryArg.classificationScenario,
          lab: queryArg.lab,
          scanner: queryArg.scanner,
          staining: queryArg.staining,
          staining_detail: queryArg.stainingDetail,
          organ: queryArg.organ,
          username: queryArg.username,
          previous_user: queryArg.previousUser,
          task: queryArg.task,
          roi_type: queryArg.roiType,
          reviewed: queryArg.reviewed,
          pathologist: queryArg.pathologist,
          annotator: queryArg.annotator,
          qa_quality: queryArg.qaQuality,
          backlog_id: queryArg.backlogId,
          dataset_partition: queryArg.datasetPartition,
          exclude: queryArg.exclude,
          is_completed: queryArg.isCompleted,
          page: queryArg.page,
        },
      }),
    }),
    getOutputJobsCsv: build.query<
      GetOutputJobsCsvApiResponse,
      GetOutputJobsCsvApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/outputs/csv/`,
        params: {
          workflow: queryArg.workflow,
          classification_scenario: queryArg.classificationScenario,
          lab: queryArg.lab,
          scanner: queryArg.scanner,
          staining: queryArg.staining,
          staining_detail: queryArg.stainingDetail,
          organ: queryArg.organ,
          username: queryArg.username,
          previous_user: queryArg.previousUser,
          task: queryArg.task,
          roi_type: queryArg.roiType,
          reviewed: queryArg.reviewed,
          pathologist: queryArg.pathologist,
          annotator: queryArg.annotator,
          qa_quality: queryArg.qaQuality,
          backlog_id: queryArg.backlogId,
          dataset_partition: queryArg.datasetPartition,
          exclude: queryArg.exclude,
          is_completed: queryArg.isCompleted,
        },
      }),
    }),
    getJobTree: build.query<GetJobTreeApiResponse, GetJobTreeApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/tree`,
      }),
    }),
    claimJob: build.mutation<ClaimJobApiResponse, ClaimJobApiArg>({
      query: () => ({ url: `/api/v2/annotations/jobs/claim/`, method: "POST" }),
    }),
    getJobAnnotations: build.query<
      GetJobAnnotationsApiResponse,
      GetJobAnnotationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/annotations`,
      }),
    }),
    getJobAnnotationsDict: build.query<
      GetJobAnnotationsDictApiResponse,
      GetJobAnnotationsDictApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/annotations-dict`,
      }),
    }),
    getJobClassifications: build.query<
      GetJobClassificationsApiResponse,
      GetJobClassificationsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/classifications`,
      }),
    }),
    appendJobEvents: build.mutation<
      AppendJobEventsApiResponse,
      AppendJobEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/events`,
        method: "POST",
        body: queryArg.events,
      }),
    }),
    updateJob: build.mutation<UpdateJobApiResponse, UpdateJobApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/update`,
        method: "PATCH",
        body: queryArg.updateJobSchemaPatch,
        params: {
          force: queryArg.force,
        },
      }),
    }),
    completeJob: build.mutation<CompleteJobApiResponse, CompleteJobApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/jobs/${queryArg.id}/complete`,
        method: "POST",
        params: {
          username: queryArg.username,
          task_id: queryArg.taskId,
        },
      }),
    }),
    getPipeline: build.query<GetPipelineApiResponse, GetPipelineApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/pipelines/${queryArg.id}`,
      }),
    }),
    getPipelines: build.query<GetPipelinesApiResponse, GetPipelinesApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/annotations/pipelines/`,
        params: {
          image: queryArg.image,
          name: queryArg.name,
          lab: queryArg.lab,
          staining: queryArg.staining,
          organ: queryArg.organ,
          scanner: queryArg.scanner,
          workflow: queryArg.workflow,
          classification_scenario: queryArg.classificationScenario,
          backlog: queryArg.backlog,
          priority: queryArg.priority,
          is_wip: queryArg.isWip,
          page: queryArg.page,
        },
      }),
    }),
    createPipeline: build.mutation<
      CreatePipelineApiResponse,
      CreatePipelineApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/annotations/pipelines/`,
        method: "POST",
        body: queryArg.pipelineCreateSchema,
      }),
    }),
    getBacklogs: build.query<GetBacklogsApiResponse, GetBacklogsApiArg>({
      query: () => ({ url: `/api/v2/annotations/pipelines/backlogs/` }),
    }),
    getImages: build.query<GetImagesApiResponse, GetImagesApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/images/images/`,
        params: {
          name: queryArg.name,
          project: queryArg.project,
          lab: queryArg.lab,
          scanner: queryArg.scanner,
          organ: queryArg.organ,
          tumor_type: queryArg.tumorType,
          tumor_subtype: queryArg.tumorSubtype,
          staining: queryArg.staining,
          staining_detail: queryArg.stainingDetail,
          exclude: queryArg.exclude,
          has_annotations: queryArg.hasAnnotations,
          qa_status: queryArg.qaStatus,
          page: queryArg.page,
        },
      }),
    }),
    getImage: build.query<GetImageApiResponse, GetImageApiArg>({
      query: (queryArg) => ({ url: `/api/v2/images/images/${queryArg.id}` }),
    }),
    postWsiRegion: build.mutation<
      PostWsiRegionApiResponse,
      PostWsiRegionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/images/images/${queryArg.id}/wsi-region`,
        method: "POST",
        body: queryArg.wsiRegion,
      }),
    }),
    getProjectOverview: build.query<
      GetProjectOverviewApiResponse,
      GetProjectOverviewApiArg
    >({
      query: () => ({ url: `/api/v2/images/images/project-overview/` }),
    }),
    getProjects: build.query<GetProjectsApiResponse, GetProjectsApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/images/images/projects/`,
        params: {
          page: queryArg.page,
        },
      }),
    }),
    getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/images/images/projects/${queryArg.id}`,
      }),
    }),
    getClassifications: build.query<
      GetClassificationsApiResponse,
      GetClassificationsApiArg
    >({
      query: () => ({ url: `/api/v2/images/classifications/` }),
    }),
    getClassificationScenarios: build.query<
      GetClassificationScenariosApiResponse,
      GetClassificationScenariosApiArg
    >({
      query: () => ({ url: `/api/v2/images/classification-scenarios/` }),
    }),
    getClassificationScenario: build.query<
      GetClassificationScenarioApiResponse,
      GetClassificationScenarioApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/images/classification-scenarios/${queryArg.id}`,
      }),
    }),
    getScans: build.query<GetScansApiResponse, GetScansApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/scans/`,
        params: {
          lab: queryArg.lab,
          exclude: queryArg.exclude,
          page: queryArg.page,
        },
      }),
    }),
    getScan: build.query<GetScanApiResponse, GetScanApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/scans/${queryArg.anonymizationName}`,
      }),
    }),
    updateScan: build.mutation<UpdateScanApiResponse, UpdateScanApiArg>({
      query: (queryArg) => ({
        url: `/api/v2/scans/${queryArg.anonymizationName}`,
        method: "PUT",
        body: queryArg.updateScanSchema,
      }),
    }),
    getLabs: build.query<GetLabsApiResponse, GetLabsApiArg>({
      query: () => ({ url: `/api/v2/scans/labs/` }),
    }),
    getOrgans: build.query<GetOrgansApiResponse, GetOrgansApiArg>({
      query: () => ({ url: `/api/v2/scans/organs/` }),
    }),
    getStainings: build.query<GetStainingsApiResponse, GetStainingsApiArg>({
      query: () => ({ url: `/api/v2/scans/stainings/` }),
    }),
    getStainingDetails: build.query<
      GetStainingDetailsApiResponse,
      GetStainingDetailsApiArg
    >({
      query: () => ({ url: `/api/v2/scans/staining-details/` }),
    }),
    getScanners: build.query<GetScannersApiResponse, GetScannersApiArg>({
      query: () => ({ url: `/api/v2/scans/scanners/` }),
    }),
    getOriginalDatasets: build.query<
      GetOriginalDatasetsApiResponse,
      GetOriginalDatasetsApiArg
    >({
      query: () => ({ url: `/api/v2/scans/original-datasets/` }),
    }),
    getTumorTypes: build.query<GetTumorTypesApiResponse, GetTumorTypesApiArg>({
      query: () => ({ url: `/api/v2/scans/tumor-types/` }),
    }),
    getTumorSubtypes: build.query<
      GetTumorSubtypesApiResponse,
      GetTumorSubtypesApiArg
    >({
      query: () => ({ url: `/api/v2/scans/tumor-subtypes/` }),
    }),
    getSignificantCharacteristics: build.query<
      GetSignificantCharacteristicsApiResponse,
      GetSignificantCharacteristicsApiArg
    >({
      query: () => ({ url: `/api/v2/scans/significant-characteristics/` }),
    }),
    getSpecimenTypes: build.query<
      GetSpecimenTypesApiResponse,
      GetSpecimenTypesApiArg
    >({
      query: () => ({ url: `/api/v2/scans/specimen-type/` }),
    }),
    createWsiResult: build.mutation<
      CreateWsiResultApiResponse,
      CreateWsiResultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/wsi/scan`,
        method: "POST",
        body: queryArg.wsiScanCreateIn,
        params: {
          force: queryArg.force,
          trigger: queryArg.trigger,
        },
      }),
    }),
    readWsiScan: build.query<ReadWsiScanApiResponse, ReadWsiScanApiArg>({
      query: (queryArg) => ({ url: `/api/v2/wsi/scan/${queryArg.id}` }),
    }),
    updateWsiScan: build.mutation<
      UpdateWsiScanApiResponse,
      UpdateWsiScanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/wsi/scan/${queryArg.id}`,
        method: "PUT",
        body: queryArg.wsiScanUpdateIn,
        params: {
          force: queryArg.force,
        },
      }),
    }),
    deleteWsiScan: build.mutation<
      DeleteWsiScanApiResponse,
      DeleteWsiScanApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v2/wsi/scan/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as imageServerApi };
export type ImageServerAppsUsersApiUserAuthTokenApiResponse =
  /** status 200 OK */ UserAuthOut;
export type ImageServerAppsUsersApiUserAuthTokenApiArg = {
  userAuthIn: UserAuthIn;
};
export type GetUsersApiResponse = /** status 200 OK */ UserSchema[];
export type GetUsersApiArg = {
  group?: string | null;
};
export type GetGroupApiResponse = /** status 200 OK */ GroupSchema;
export type GetGroupApiArg = {
  groupId: number;
};
export type GetGroupsApiResponse = /** status 200 OK */ GroupSchema[];
export type GetGroupsApiArg = void;
export type GetTasksApiResponse = /** status 200 OK */ TaskSchema[];
export type GetTasksApiArg = {
  workflow?: string | null;
};
export type GetTaskApiResponse = /** status 200 OK */ TaskSchema;
export type GetTaskApiArg = {
  name: string;
};
export type GetWorkflowsApiResponse = /** status 200 OK */ WorkflowSchema[];
export type GetWorkflowsApiArg = void;
export type GetWorkflowApiResponse = /** status 200 OK */ WorkflowSchema;
export type GetWorkflowApiArg = {
  name: string;
};
export type GetJobApiResponse = /** status 200 OK */ JobSchema;
export type GetJobApiArg = {
  id: string;
};
export type GetJobsApiResponse = /** status 200 OK */ PagedJobSchema;
export type GetJobsApiArg = {
  workflow?: string | null;
  classificationScenario?: string[] | null;
  lab?: number[] | null;
  scanner?: number[] | null;
  staining?: number[] | null;
  stainingDetail?: number[] | null;
  organ?: number[] | null;
  username?: string[] | null;
  previousUser?: string[] | null;
  task?: string[] | null;
  roiType?: string[] | null;
  reviewed?: boolean | null;
  pathologist?: string | null;
  annotator?: string | null;
  qaQuality?: ("excellent" | "good" | "bad" | "very_bad" | "exclude")[] | null;
  backlogId?: string | null;
  datasetPartition?:
    | (
        | "train"
        | "test"
        | "unassigned"
        | "validation"
        | "train_validation"
        | "generalisation"
      )[]
    | null;
  exclude?: boolean | null;
  isCompleted?: boolean | null;
  page?: number;
};
export type GetOutputJobsApiResponse =
  /** status 200 OK */ PagedAnnotationOutputSchema;
export type GetOutputJobsApiArg = {
  workflow?: string | null;
  classificationScenario?: string[] | null;
  lab?: number[] | null;
  scanner?: number[] | null;
  staining?: number[] | null;
  stainingDetail?: number[] | null;
  organ?: number[] | null;
  username?: string[] | null;
  previousUser?: string[] | null;
  task?: string[] | null;
  roiType?: string[] | null;
  reviewed?: boolean | null;
  pathologist?: string | null;
  annotator?: string | null;
  qaQuality?: ("excellent" | "good" | "bad" | "very_bad" | "exclude")[] | null;
  backlogId?: string | null;
  datasetPartition?:
    | (
        | "train"
        | "test"
        | "unassigned"
        | "validation"
        | "train_validation"
        | "generalisation"
      )[]
    | null;
  exclude?: boolean | null;
  isCompleted?: boolean | null;
  page?: number;
};
export type GetOutputJobsCsvApiResponse = unknown;
export type GetOutputJobsCsvApiArg = {
  workflow?: string | null;
  classificationScenario?: string[] | null;
  lab?: number[] | null;
  scanner?: number[] | null;
  staining?: number[] | null;
  stainingDetail?: number[] | null;
  organ?: number[] | null;
  username?: string[] | null;
  previousUser?: string[] | null;
  task?: string[] | null;
  roiType?: string[] | null;
  reviewed?: boolean | null;
  pathologist?: string | null;
  annotator?: string | null;
  qaQuality?: ("excellent" | "good" | "bad" | "very_bad" | "exclude")[] | null;
  backlogId?: string | null;
  datasetPartition?:
    | (
        | "train"
        | "test"
        | "unassigned"
        | "validation"
        | "train_validation"
        | "generalisation"
      )[]
    | null;
  exclude?: boolean | null;
  isCompleted?: boolean | null;
};
export type GetJobTreeApiResponse = /** status 200 OK */ JobTreeSchema;
export type GetJobTreeApiArg = {
  id: string;
};
export type ClaimJobApiResponse = /** status 200 OK */ Message;
export type ClaimJobApiArg = void;
export type GetJobAnnotationsApiResponse =
  /** status 200 OK */ AnnotationsSnapshotLists;
export type GetJobAnnotationsApiArg = {
  id: string;
};
export type GetJobAnnotationsDictApiResponse =
  /** status 200 OK */ AnnotationsSnapshotDict;
export type GetJobAnnotationsDictApiArg = {
  id: string;
};
export type GetJobClassificationsApiResponse =
  /** status 200 OK */ ClassificationSchema[];
export type GetJobClassificationsApiArg = {
  id: string;
};
export type AppendJobEventsApiResponse = /** status 200 OK */ Message;
export type AppendJobEventsApiArg = {
  id: string;
  events: (
    | AddRoiEventSchema
    | UpdateCellEventSchema
    | UpdateRoiEventSchema
    | UpdatePolygonEventSchema
    | AddCellEventSchema
    | AddPolygonEventSchema
    | DeleteAnnotationEventSchema
    | AddCommentEventSchema
    | ResolveCommentEventSchema
    | QaQualityQuickCheckEventSchema
  )[];
};
export type UpdateJobApiResponse = /** status 200 OK */ Message;
export type UpdateJobApiArg = {
  id: string;
  force?: boolean;
  updateJobSchemaPatch: UpdateJobSchemaPatch;
};
export type CompleteJobApiResponse = /** status 200 OK */ Message;
export type CompleteJobApiArg = {
  id: string;
  username?: string | null;
  taskId?: string | null;
};
export type GetPipelineApiResponse = /** status 200 OK */ PipelineSchema;
export type GetPipelineApiArg = {
  id: string;
};
export type GetPipelinesApiResponse = /** status 200 OK */ PagedPipelineSchema;
export type GetPipelinesApiArg = {
  image?: string | null;
  name?: string | null;
  lab?: number[] | null;
  staining?: number[] | null;
  organ?: number[] | null;
  scanner?: number[] | null;
  workflow?: string | null;
  classificationScenario?: string[] | null;
  backlog?: string[] | null;
  priority?: number[] | null;
  isWip?: boolean | null;
  page?: number;
};
export type CreatePipelineApiResponse = /** status 200 OK */ Message;
export type CreatePipelineApiArg = {
  pipelineCreateSchema: PipelineCreateSchema;
};
export type GetBacklogsApiResponse = /** status 200 OK */ BacklogSchema[];
export type GetBacklogsApiArg = void;
export type GetImagesApiResponse = /** status 200 OK */ PagedImageSchema;
export type GetImagesApiArg = {
  name?: string | null;
  project?: string | null;
  lab?: number[] | null;
  scanner?: number[] | null;
  organ?: number[] | null;
  tumorType?: number[] | null;
  tumorSubtype?: number[] | null;
  staining?: number[] | null;
  stainingDetail?: number[] | null;
  exclude?: boolean | null;
  hasAnnotations?: boolean | null;
  qaStatus?: string | null;
  page?: number;
};
export type GetImageApiResponse = /** status 200 OK */ ImageSchema;
export type GetImageApiArg = {
  id: string;
};
export type PostWsiRegionApiResponse = /** status 200 OK */ Message;
export type PostWsiRegionApiArg = {
  id: string;
  wsiRegion: number[];
};
export type GetProjectOverviewApiResponse =
  /** status 200 OK */ ProjectSchema[];
export type GetProjectOverviewApiArg = void;
export type GetProjectsApiResponse =
  /** status 200 OK */ PagedProjectDetailSchema;
export type GetProjectsApiArg = {
  page?: number;
};
export type GetProjectApiResponse = /** status 200 OK */ ProjectDetailSchema;
export type GetProjectApiArg = {
  id: string;
};
export type GetClassificationsApiResponse =
  /** status 200 OK */ ClassificationSchema[];
export type GetClassificationsApiArg = void;
export type GetClassificationScenariosApiResponse =
  /** status 200 OK */ ClassificationScenarioSchema[];
export type GetClassificationScenariosApiArg = void;
export type GetClassificationScenarioApiResponse =
  /** status 200 OK */ ClassificationScenarioDetailSchema;
export type GetClassificationScenarioApiArg = {
  id: string;
};
export type GetScansApiResponse = /** status 200 OK */ PagedScanSchema;
export type GetScansApiArg = {
  lab?: number[] | null;
  exclude?: boolean | null;
  page?: number;
};
export type GetScanApiResponse = /** status 200 OK */ ScanSchema;
export type GetScanApiArg = {
  anonymizationName: string;
};
export type UpdateScanApiResponse = /** status 200 OK */ Message;
export type UpdateScanApiArg = {
  anonymizationName: string;
  updateScanSchema: UpdateScanSchema;
};
export type GetLabsApiResponse = /** status 200 OK */ LabSchema[];
export type GetLabsApiArg = void;
export type GetOrgansApiResponse = /** status 200 OK */ OrganSchema[];
export type GetOrgansApiArg = void;
export type GetStainingsApiResponse = /** status 200 OK */ StainingSchema[];
export type GetStainingsApiArg = void;
export type GetStainingDetailsApiResponse =
  /** status 200 OK */ StainingDetailSchema[];
export type GetStainingDetailsApiArg = void;
export type GetScannersApiResponse = /** status 200 OK */ ScannerSchema[];
export type GetScannersApiArg = void;
export type GetOriginalDatasetsApiResponse =
  /** status 200 OK */ OriginalDatasetSchema[];
export type GetOriginalDatasetsApiArg = void;
export type GetTumorTypesApiResponse = /** status 200 OK */ TumorTypeSchema[];
export type GetTumorTypesApiArg = void;
export type GetTumorSubtypesApiResponse =
  /** status 200 OK */ TumorSubtypeSchema[];
export type GetTumorSubtypesApiArg = void;
export type GetSignificantCharacteristicsApiResponse =
  /** status 200 OK */ SignificantCharacteristicSchema[];
export type GetSignificantCharacteristicsApiArg = void;
export type GetSpecimenTypesApiResponse =
  /** status 200 OK */ SpecimenTypeSchema[];
export type GetSpecimenTypesApiArg = void;
export type CreateWsiResultApiResponse = /** status 200 OK */ WsiScanOut;
export type CreateWsiResultApiArg = {
  /** Drop existing WSI Result with the same parameters if it is already exist. */
  force?: boolean;
  /** Trigger WSI scan. This parameter will be skipped if result value present. */
  trigger?: boolean;
  wsiScanCreateIn: WsiScanCreateIn;
};
export type ReadWsiScanApiResponse = /** status 200 OK */ WsiScanOut;
export type ReadWsiScanApiArg = {
  id: string;
};
export type UpdateWsiScanApiResponse = /** status 200 OK */ WsiScanOut;
export type UpdateWsiScanApiArg = {
  id: string;
  /** Drop existing WSI Result with the same parameters if it is already exist. */
  force?: boolean;
  wsiScanUpdateIn: WsiScanUpdateIn;
};
export type DeleteWsiScanApiResponse = unknown;
export type DeleteWsiScanApiArg = {
  id: string;
};
export type UserAuthOut = {
  access_token: string;
  access_expires_in: number;
};
export type UserAuthIn = {
  username: string;
  password: string;
};
export type UserSchema = {
  id?: string | null;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  /** Setting a lab_id (custom ai-model / postprocessing) for DemoIMS users. Check cognito for deep integration / direct api users */
  lab_id?: number;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups: number[];
};
export type Message = {
  message: string;
};
export type GroupSchema = {
  id?: number | null;
  name: string;
};
export type TaskSchema = {
  name?: string | null;
  description: string;
  previous_task?: string | null;
  workflow: string;
  eligible_user_group?: number | null;
  is_per_roi?: boolean;
};
export type WorkflowSchema = {
  name: string;
  description: string;
  tasks: TaskSchema[];
};
export type LabSchema = {
  id: number;
  name: string;
};
export type OrganSchema = {
  id: number;
  name: string;
  short_name?: string | null;
};
export type ScoreSchema = {
  type: string;
  value: number;
  reference?: string | null;
  source_pathologist?: number | null;
  source_lab?: number | null;
  notes?: string | null;
  created?: string;
  updated: string;
};
export type StainingSchema = {
  id: number;
  name: string;
  short_name: string;
  default_organ?: number | null;
};
export type TumorTypeSchema = {
  id: number;
  name: string;
};
export type TumorSubtypeSchema = {
  id: number;
  name: string;
};
export type ScanSchema = {
  anonymization_name: string;
  assessable: boolean | null;
  case: string;
  class_field?: string | null;
  date: string;
  diagnosis?: string | null;
  exclude: boolean;
  id: number;
  image_type: string | null;
  lab_slide_source: string;
  lab: LabSchema;
  magnification: number;
  mindpeak_products_ids?: number[];
  notes: string;
  organ_details: string;
  organ?: OrganSchema | null;
  original_dataset?: string | null;
  original_name?: string | null;
  ot_text: string;
  pathology_status?: string | null;
  pathozoom_image_id?: number | null;
  pathozoom_link: string;
  product_scope: boolean | null;
  quality: string;
  report_results: string;
  scan_counter: number;
  scanner: string;
  scanning_officer: string;
  scores?: ScoreSchema[];
  significant_characteristics?: string[];
  slide_number: string;
  specimen_type?: string | null;
  stainer?: string | null;
  staining_detail?: string | null;
  staining: StainingSchema;
  tumor_stage?: string | null;
  tumor_type?: TumorTypeSchema | null;
  tumor_subtype?: TumorSubtypeSchema | null;
  tumor_location: string | null;
  physical_slide_creation_year?: number | null;
};
export type ScanQaStatus = "passed" | "failed" | "not_checked";
export type ImageSchema = {
  id: string;
  scan: ScanSchema | null;
  num_pipelines?: number;
  wsi_region?: number[] | null;
  qa_status?: ScanQaStatus;
  upload: string;
  case?: string | null;
  name?: string | null;
  file_type?: string | null;
  size?: number;
  ai_scenario?: string | null;
  wsi_region_unit?: string;
};
export type JobSchema = {
  id: string;
  image: ImageSchema;
  username?: string | null;
  previously_involved_usernames?: string[];
  num_annotation_events: number;
  num_annotations?: number;
  is_output: boolean;
  workflow: string;
  dataset_partition:
    | "train"
    | "test"
    | "unassigned"
    | "validation"
    | "train_validation"
    | "generalisation";
  pipeline: string;
  annotation_type?: string;
  version: number;
  classification_scenario?: string | null;
  is_completed?: boolean;
  task: string;
  roi_id?: string | null;
  previous_job?: string | null;
  created?: string;
  assigned_at?: string | null;
  completed_at?: string | null;
  exclude?: boolean;
  comment?: string | null;
};
export type PagedJobSchema = {
  items: JobSchema[];
  count: number;
};
export type AnnotationOutputSchema = {
  id: string;
  image_id: string;
  image_name: string;
  workflow: string;
  dataset_partition: string;
  comment: string;
  backlog_id?: string | null;
  classification_scenario: string;
  pathologist?: string | null;
  annotator?: string | null;
  latest_annotation_event_date?: string | null;
  task: string;
  completed_at: string;
  exclude: boolean;
  roi_id: string | null;
  num_annotations?: number;
  roi_type?: string | null;
  qa_quality?: string | null;
  reviewed?: boolean;
  anonymization_name?: string;
  case?: string;
  lab?: string;
  organ?: string;
  scanner?: string;
  staining?: string;
  staining_detail?: string;
  microns_per_pixel?: string;
  file_extension: string;
  mindpeak_products?: string | null;
  significant_characteristics?: string | null;
  original_dataset?: string;
  tumor_type?: string;
  tumor_stage?: string | null;
  notes?: string;
  diagnosis?: string | null;
};
export type PagedAnnotationOutputSchema = {
  items: AnnotationOutputSchema[];
  count: number;
};
export type JobTreeSchema = {
  id: string;
  username: string | null;
  next_jobs: JobTreeSchema[];
  is_completed: boolean;
  task_name: string;
  branch_id: string;
};
export type RoiAnnotationDict = {
  id: string;
  name: string;
  coordinates: [number, number][];
  type: ("tumor_hotspot" | "anti" | "white_area") | null;
  last_editor: string;
};
export type CellAnnotationDict = {
  id: string;
  name: string;
  coordinate: [number, number];
  class_id: string;
  markers: string[];
  is_reviewed: boolean;
  last_editor: string;
};
export type PolygonAnnotationDict = {
  id: string;
  name: string;
  coordinates: [number, number][];
  class_id: string;
  is_reviewed: boolean;
  is_ai_generated?: boolean;
  last_editor: string;
};
export type CommentAnnotationDict = {
  id: string;
  content: string;
  username: string;
  coordinates?: [number, number][];
  timestamp: string;
  is_resolved: boolean;
  type: string;
};
export type AnnotationMetaQaSchema = {
  quality: "excellent" | "good" | "bad" | "very_bad" | "exclude";
  timestamp: string;
  username: string;
};
export type AnnotationMetaDict = {
  qa: AnnotationMetaQaSchema | null;
  roi_type: string | null;
  annotator: string | null;
  pathologist: string | null;
  all_involved_usernames: string[];
  reviewed: boolean;
  num_annotations: number;
  latest_session_start_date: string | null;
  latest_annotation_event_date: string | null;
};
export type AnnotationsSnapshotLists = {
  rois: RoiAnnotationDict[];
  cells: CellAnnotationDict[];
  polygons: PolygonAnnotationDict[];
  comments: CommentAnnotationDict[];
  meta: AnnotationMetaDict;
};
export type AnnotationsSnapshotDict = {
  rois: {
    [key: string]: RoiAnnotationDict;
  };
  cells: {
    [key: string]: CellAnnotationDict;
  };
  polygons: {
    [key: string]: PolygonAnnotationDict;
  };
  comments: {
    [key: string]: CommentAnnotationDict;
  };
  meta: AnnotationMetaDict;
};
export type ClassificationSchema = {
  id: string;
  name?: string | null;
  /** If not specified, this will default to the value of 'name'. */
  display_name?: string | null;
  color?: string | null;
  classification_scenario: string;
  hot_key?: string | null;
  priority?: number;
};
export type AddRoiPayloadSchema = {
  id: string;
  name: string;
  coordinates: [number, number][];
};
export type AddRoiEventSchema = {
  id: string;
  type: "ADD_ROI";
  timestamp: string;
  payload: AddRoiPayloadSchema;
};
export type UpdateCellPayloadSchema = {
  id: string;
  name?: string | null;
  class_id?: string | null;
  is_reviewed?: boolean | null;
  coordinate?: [number, number] | null;
  markers?: string[] | null;
};
export type UpdateCellEventSchema = {
  id: string;
  type: "UPDATE_CELL";
  timestamp: string;
  payload: UpdateCellPayloadSchema;
};
export type UpdateRoiPayloadSchema = {
  id: string;
  name?: string | null;
  coordinates?: [number, number][] | null;
};
export type UpdateRoiEventSchema = {
  id: string;
  type: "UPDATE_ROI";
  timestamp: string;
  payload: UpdateRoiPayloadSchema;
};
export type UpdatePolygonPayloadSchema = {
  id: string;
  name?: string | null;
  class_id?: string | null;
  is_reviewed?: boolean | null;
  coordinates?: [number, number][] | null;
  is_ai_generated?: boolean | null;
};
export type UpdatePolygonEventSchema = {
  id: string;
  type: "UPDATE_POLYGON";
  timestamp: string;
  payload: UpdatePolygonPayloadSchema;
};
export type AddCellPayloadSchema = {
  id: string;
  name: string;
  coordinate: [number, number];
  class_id: string;
  markers?: string[];
  is_reviewed?: boolean;
};
export type AddCellEventSchema = {
  id: string;
  type: "ADD_CELL";
  timestamp: string;
  payload: AddCellPayloadSchema;
};
export type AddPolygonPayloadSchema = {
  id: string;
  name: string;
  coordinates: [number, number][];
  class_id: string;
  is_reviewed?: boolean;
  is_ai_generated?: boolean;
};
export type AddPolygonEventSchema = {
  id: string;
  type: "ADD_POLYGON";
  timestamp: string;
  payload: AddPolygonPayloadSchema;
};
export type DeleteAnnotationPayloadSchema = {
  id: string;
};
export type DeleteAnnotationEventSchema = {
  id: string;
  type: "DELETE_CELL" | "DELETE_POLYGON" | "DELETE_ROI";
  timestamp: string;
  payload: DeleteAnnotationPayloadSchema;
};
export type AddCommentPayloadSchema = {
  id: string;
  content: string;
  coordinates: [number, number][] | null;
  type?: string | null;
};
export type AddCommentEventSchema = {
  id: string;
  type: "ADD_COMMENT";
  timestamp: string;
  payload: AddCommentPayloadSchema;
};
export type ResolveCommentPayloadSchema = {
  id: string;
  username: string;
};
export type ResolveCommentEventSchema = {
  id: string;
  type: "RESOLVE_COMMENT";
  timestamp: string;
  payload: ResolveCommentPayloadSchema;
};
export type QaQualityQuickCheckPayloadSchema = {
  quality: "excellent" | "good" | "bad" | "very_bad" | "exclude";
};
export type QaQualityQuickCheckEventSchema = {
  id: string;
  type: "QA_QUICK_CHECK";
  timestamp: string;
  payload: QaQualityQuickCheckPayloadSchema;
};
export type UpdateJobSchemaPatch = {
  dataset_partition?:
    | (
        | "train"
        | "test"
        | "unassigned"
        | "validation"
        | "train_validation"
        | "generalisation"
      )
    | null;
  exclude?: boolean | null;
};
export type PipelineSchema = {
  image: ImageSchema;
  is_wip: boolean;
  active_users: string[];
  num_jobs: number;
  num_leaf_jobs: number;
  root_job_id?: string | null;
  id?: string | null;
  name: string;
  backlog?: string | null;
  classification_scenario?: string | null;
  priority?: number;
  workflow: string;
};
export type PagedPipelineSchema = {
  items: PipelineSchema[];
  count: number;
};
export type PipelineCreateSchema = {
  image_ids: string[];
  classification_scenario_id: string;
  backlog_id?: string | null;
  workflow: string;
  priority?: number | null;
};
export type BacklogSchema = {
  num_pipelines_total: number;
  num_jobs_total: number;
  num_jobs_completed: number;
  num_leaf_node_jobs: number;
  num_output_jobs: number;
  id?: string | null;
  name: string;
};
export type PagedImageSchema = {
  items: ImageSchema[];
  count: number;
};
export type ProjectSchema = {
  id: string;
  name: string;
};
export type ProjectDetailSchema = {
  id: string;
  name: string;
  description?: string | null;
  images: string[];
  cases: string[];
};
export type PagedProjectDetailSchema = {
  items: ProjectDetailSchema[];
  count: number;
};
export type ClassificationScenarioSchema = {
  id: string;
  name: string;
  /** (This allows Polygon annotation on an Image) */
  allow_polygon_annotation?: boolean;
};
export type ClassificationScenarioDetailSchema = {
  id: string;
  name: string;
  /** (This allows Polygon annotation on an Image) */
  allow_polygon_annotation?: boolean;
  classifications: ClassificationSchema[];
};
export type PagedScanSchema = {
  items: ScanSchema[];
  count: number;
};
export type UpdateScanSchema = {
  assessable: boolean | null;
  exclude: boolean | null;
  image_type: string | null;
  organ: number | null;
  product_scope: boolean | null;
  significant_characteristics: string[] | null;
  specimen_type: string | null;
  tumor_location: string | null;
  tumor_type: number | null;
};
export type StainingDetailSchema = {
  id: number;
  name: string;
  short_name?: string | null;
};
export type ScannerSchema = {
  id: number;
  name: string;
};
export type OriginalDatasetSchema = {
  id: number;
  name: string;
  url?: string | null;
  local_url?: string | null;
  comment?: string | null;
  cell_annotations?: string[] | null;
  cell_annotations_comment?: string | null;
  tissue_annotations?: string[] | null;
  tissue_annotations_comment?: string | null;
  scans_status: string;
  annotations_status: string;
  license: string;
  clinical_data_types?: string[] | null;
  related_datasets?: string[] | null;
  responsible_user?: string | null;
};
export type SignificantCharacteristicSchema = {
  name?: string;
};
export type SpecimenTypeSchema = {
  id: number;
  name: string;
};
export type WsiScanOut = {
  image_name: string;
  ai_scenario: string;
  created_by: string;
  id?: string | null;
  lab_id: number;
  store_polygons?: boolean;
  detect_tissue?: boolean;
  exclude_tissue?: boolean;
  high_priority?: boolean;
  status?: string;
  details?: string;
};
export type WsiScanCreateIn = {
  /** Image file name with extension */
  image_name: string;
  ai_scenario: string;
  lab_id: number;
  destination_lab_id?: number | null;
  store_polygons?: boolean | null;
  detect_tissue?: boolean | null;
  exclude_tissue?: boolean | null;
  high_priority?: boolean | null;
  result?: object | null;
};
export type Status =
  | "created"
  | "started"
  | "processing"
  | "complete"
  | "error";
export type WsiScanUpdateIn = {
  lab_id?: number;
  status?: Status;
  details?: string;
  result_url?: string;
};
export const {
  useImageServerAppsUsersApiUserAuthTokenMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetGroupQuery,
  useLazyGetGroupQuery,
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
  useGetTasksQuery,
  useLazyGetTasksQuery,
  useGetTaskQuery,
  useLazyGetTaskQuery,
  useGetWorkflowsQuery,
  useLazyGetWorkflowsQuery,
  useGetWorkflowQuery,
  useLazyGetWorkflowQuery,
  useGetJobQuery,
  useLazyGetJobQuery,
  useGetJobsQuery,
  useLazyGetJobsQuery,
  useGetOutputJobsQuery,
  useLazyGetOutputJobsQuery,
  useGetOutputJobsCsvQuery,
  useLazyGetOutputJobsCsvQuery,
  useGetJobTreeQuery,
  useLazyGetJobTreeQuery,
  useClaimJobMutation,
  useGetJobAnnotationsQuery,
  useLazyGetJobAnnotationsQuery,
  useGetJobAnnotationsDictQuery,
  useLazyGetJobAnnotationsDictQuery,
  useGetJobClassificationsQuery,
  useLazyGetJobClassificationsQuery,
  useAppendJobEventsMutation,
  useUpdateJobMutation,
  useCompleteJobMutation,
  useGetPipelineQuery,
  useLazyGetPipelineQuery,
  useGetPipelinesQuery,
  useLazyGetPipelinesQuery,
  useCreatePipelineMutation,
  useGetBacklogsQuery,
  useLazyGetBacklogsQuery,
  useGetImagesQuery,
  useLazyGetImagesQuery,
  useGetImageQuery,
  useLazyGetImageQuery,
  usePostWsiRegionMutation,
  useGetProjectOverviewQuery,
  useLazyGetProjectOverviewQuery,
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useGetClassificationsQuery,
  useLazyGetClassificationsQuery,
  useGetClassificationScenariosQuery,
  useLazyGetClassificationScenariosQuery,
  useGetClassificationScenarioQuery,
  useLazyGetClassificationScenarioQuery,
  useGetScansQuery,
  useLazyGetScansQuery,
  useGetScanQuery,
  useLazyGetScanQuery,
  useUpdateScanMutation,
  useGetLabsQuery,
  useLazyGetLabsQuery,
  useGetOrgansQuery,
  useLazyGetOrgansQuery,
  useGetStainingsQuery,
  useLazyGetStainingsQuery,
  useGetStainingDetailsQuery,
  useLazyGetStainingDetailsQuery,
  useGetScannersQuery,
  useLazyGetScannersQuery,
  useGetOriginalDatasetsQuery,
  useLazyGetOriginalDatasetsQuery,
  useGetTumorTypesQuery,
  useLazyGetTumorTypesQuery,
  useGetTumorSubtypesQuery,
  useLazyGetTumorSubtypesQuery,
  useGetSignificantCharacteristicsQuery,
  useLazyGetSignificantCharacteristicsQuery,
  useGetSpecimenTypesQuery,
  useLazyGetSpecimenTypesQuery,
  useCreateWsiResultMutation,
  useReadWsiScanQuery,
  useLazyReadWsiScanQuery,
  useUpdateWsiScanMutation,
  useDeleteWsiScanMutation,
} = injectedRtkApi;
