import { Layout, Menu, MenuProps } from 'antd';
import { BugOutlined, LogoutOutlined, InboxOutlined, PartitionOutlined, PictureOutlined, FileDoneOutlined, DiffOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router';
import { clearAnnotationDetails } from 'redux/slices/annotationDetails';
import { clearViewerOptions, selectActiveAssignmentTab, setActiveAssignmentTab } from 'redux/slices/viewerOptions';
import { USER_GROUP } from 'utils/Constants';
import { getCurrentUser, isUserQualityAssurance } from 'utils/Utils';
import LogoImage from 'assets/images/mindpeak_annotation_tool_logoform_digital_light_borderless.png';

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [initialUsername, setInitialUsername] = useState('');

  // If a User logs into a different account in a different tab, this logs out the
  // account in the first tab.
  const _handleLocalStorageChange = () => {
    const newUsername = getCurrentUser()?.username;
    if (newUsername && initialUsername) {
      if (initialUsername !== newUsername) {
        dispatch(clearAnnotationDetails());
        dispatch(clearViewerOptions());
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', _handleLocalStorageChange);

    const username = getCurrentUser()?.username || '';

    setInitialUsername(username);

    return () => {
      window.removeEventListener('storage', _handleLocalStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = () => {
    window.localStorage.clear();
    dispatch(clearAnnotationDetails());
    dispatch(clearViewerOptions());
    navigate('/login');
  };

  const goToBugReportFeatureRequestForm = () => {
    window.open('https://forms.gle/3e7s4K5PNXShCsFD7', '_blank');
  };

  const userGroupMenuItems: Record<string, Array<string>> = {
    [USER_GROUP.ANNOTATOR]: ['jobs'],
    [USER_GROUP.CANDIDATE_PATHOLOGIST]: ['jobs', 'images'],
    [USER_GROUP.PATHOLOGIST]: ['jobs', 'images'],
    [USER_GROUP.DATA_SCIENTIST]: ['jobs', 'pipelines', 'backlogs', 'images'],
    [USER_GROUP.ANNOTATION_MANAGER]: ['jobs', 'pipelines', 'backlogs', 'images']
  }

  const allLeftMenuItems: MenuProps['items'] = [
    {
      key: 'jobs',
      label: 'Jobs',
      icon: <InboxOutlined />,
      onClick: key => {
        navigate('/jobs');
      },
    },
    {
      key: 'pipelines',
      label: 'Pipelines',
      icon: <PartitionOutlined />,
      children: [
        { key: 'pipelines-inspect', label: 'Track & Inspect', onClick: () => { navigate('/pipelines') } },
        { key: 'pipelines-create', label: 'Create', onClick: () => { navigate('/pipelines/create/') } },
        { key: 'pipelines-outputs', label: 'Outputs', onClick: () => { navigate('/annotation-outputs') } }
      ],
    },
    {
      key: 'backlogs',
      label: 'Backlogs',
      icon: <DiffOutlined />,
      onClick: key => {
        navigate('/backlogs');
      },
    },
    {
      key: 'images',
      label: 'Images',
      icon: <PictureOutlined />,
      onClick: key => {
        navigate('/images');
      },
    },
  ];

  const leftMenuItems = getCurrentUser()?.groups.reduce<Set<any>>((acc: Set<any>, groupName: string) => {
    if (groupName in userGroupMenuItems) {
      userGroupMenuItems[groupName].forEach(menuItemName => acc.add(allLeftMenuItems.find(m => m?.key === menuItemName)))
    }
    return acc;
  }, new Set()) || [];

  const rightMenuItems: MenuProps['items'] = [
    {
      key: 'createBugReport',
      label: 'Feedback',
      icon: <BugOutlined />,
      onClick: () => {
        goToBugReportFeatureRequestForm();
      },
    },
    {
      key: 'username',
      label: (
        <>
          {getCurrentUser()?.username} (
          {getCurrentUser()?.groups.filter(
            group =>
              group === USER_GROUP.PATHOLOGIST ||
              group === USER_GROUP.CANDIDATE_PATHOLOGIST ||
              group === USER_GROUP.ANNOTATOR ||
              group === USER_GROUP.DATA_SCIENTIST ||
              group === USER_GROUP.ANNOTATION_MANAGER,
          ).join(', ')}
          )
        </>
      ),
      disabled: true,
    },
    {
      key: 'logout',
      label: 'Log Out',
      icon: <LogoutOutlined />,
      onClick: () => {
        logOut();
      },
    },
  ];

  return (
    <Layout.Header
      style={{ display: 'flex', alignItems: 'center' }}
    >
      <Link to="/">
        <img src={LogoImage} style={{ margin: '44px 24px 16px -32px' }} height={42} alt="Mindpeak Logo" />
      </Link>
      <Menu
        items={Array.from(leftMenuItems) as any}
        theme="dark"
        mode="horizontal"
        style={{ minWidth: 0, flex: 'auto', justifyContent: 'left' }}
      />
      <Menu
        items={rightMenuItems}
        theme="dark"
        mode="horizontal"
        style={{ minWidth: 0, flex: 'auto', justifyContent: 'right' }}
      />
    </Layout.Header>
  );
}
