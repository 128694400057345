import { BaseQueryFn, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuthorization } from 'service/api'
import { setIsReAuthDialogShown } from './userSlice';

const imageServerApiURL = new URL(`${window._env_.REACT_APP_BACKEND_API_URL || 'http://localhost:8000'}`);

/**
 * Utility function to correctly format query parameters.
 * The default paramsSerializer will format multiple query params with the same name like this ?organ=5,6
 * but our server needs multiple query params like this ?organ=5&organ=6.
 *
 * @param {Record<string, any>} params - The query parameters to serialize.
 * @returns {string} - The serialized query parameters.
 */
const serializeQueryParams = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(params)) {
    if (value === undefined) continue;
    if (Array.isArray(value)) {
      value.forEach(val => searchParams.append(key, val));
    } else {
      searchParams.append(key, value);
    }
  }

  return searchParams.toString();
};

const baseQuery = fetchBaseQuery({
  baseUrl: imageServerApiURL.toString(),
  prepareHeaders: headers => {
    const token = getAuthorization();
    if (token) {
      headers.set('Authorization', token);
    }
    return headers;
  },
  paramsSerializer: serializeQueryParams,
  responseHandler: async (response) => {
    if (response.headers.get("Content-Type")?.includes("text/csv")) {
      // Handle CSV response
      return response.text();
    }
    else {
      // Handle JSON response
      return response.json();
    }
  }
});

// Enhanced baseQuery to handle 401 errors
const baseQueryWithReAuth: BaseQueryFn = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    // Dispatch an action to show the re-authentication modal
    api.dispatch(setIsReAuthDialogShown(true));
  }

  return result;
};

/**
 * Initializes an empty API service that we'll inject endpoints into later as needed.
 */
export const emptyApi = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
