import { useContext, useEffect, useRef } from 'react';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import VectorLayer from 'ol/layer/Vector';
import Stroke from 'ol/style/Stroke';
import { useSelector } from 'react-redux';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { OL_LAYER_NAME } from 'utils/Constants';
import { Polygon } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import { Feature } from 'ol';
import { selectWSIRegion } from 'redux/slices/annotationDetails';

const WSI_REGION_FEATURE_ID = 'wsi-region-feature';

const convertWSIRegionCoordinatesToPolygonCoordinates = (wsiRegionCoordinates: number[]) => {
  const x0 = wsiRegionCoordinates[0];
  const y0 = -wsiRegionCoordinates[1];
  const x1 = wsiRegionCoordinates[2];
  const y1 = -wsiRegionCoordinates[3];

  return [
    [
      [x0, y0],
      [x1, y0],
      [x1, y1],
      [x0, y1],
      [x0, y0],
    ],
  ];
};

interface WSIRegionLayerProps {
  source: VectorSource<Feature<Polygon>>;
  visible?: boolean;
}

export default function WSIRegionLayer({ source }: WSIRegionLayerProps) {
  const { map } = useContext(OpenLayersMapContext);
  const wsiRegion = useSelector(selectWSIRegion);
  const wsiRegionLayer = useRef<VectorLayer<WSIRegionLayerProps['source']> | null>(null);

  const initOLLayer = () => {
    const layer = new VectorLayer({
      className: OL_LAYER_NAME.WSI_REGION,
      properties: {
        name: OL_LAYER_NAME.WSI_REGION,
      },
      source,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style: () => {
        return [
          new Style({
            stroke: new Stroke({
              color: '#6300cc',
              width: 2,
              lineDash: [8],
            }),
            fill: new Fill({
              color: 'rgba(0, 0, 0, 0.0)',
            }),
          }),
        ];
      },
    });
    layer.setZIndex(1);
    return layer;
  };

  useEffect(() => {
    source.clear();
    if (!wsiRegion) return;
    const convertedCoords = convertWSIRegionCoordinatesToPolygonCoordinates(wsiRegion?.coordinates);

    const newWSIRegionFeature = new Feature({
      geometry: new Polygon(convertedCoords),
    });
    newWSIRegionFeature.setId(WSI_REGION_FEATURE_ID);
    source.addFeature(newWSIRegionFeature);

    source.changed();
  }, [source, wsiRegion]);

  useEffect(() => {
    if (!map) return;

    wsiRegionLayer.current = initOLLayer();
    map.addLayer(wsiRegionLayer.current);

    if (wsiRegion) {
      const convertedCoords = convertWSIRegionCoordinatesToPolygonCoordinates(wsiRegion.coordinates);
      let wsiRegionFeature = new Feature({
        id: WSI_REGION_FEATURE_ID,
        geometry: new Polygon(convertedCoords),
      });
      source.addFeature(wsiRegionFeature);
    }

    return () => {
      if (map && wsiRegionLayer.current) {
        map.removeLayer(wsiRegionLayer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source]);

  return null;
}
