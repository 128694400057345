import { useSelector } from 'react-redux';
import { Divider, Typography } from 'antd';
import { useParams } from 'react-router';
import { ScanSchema } from 'redux/slices/imageServerApi';
import { DeploymentUnitOutlined, BgColorsOutlined, FileImageOutlined, ExperimentOutlined } from '@ant-design/icons';
import { useImage } from 'redux/hooks';

const { Text } = Typography;

interface FooterImageInfoProps {
  scanInfo?: ScanSchema;
}

const FooterImageInfo: React.FC<FooterImageInfoProps> = ({ scanInfo }) => {
  const { jobId } = useParams();
  const { image } = useImage();

  if (scanInfo) {
    return (
      <>
        <FileImageOutlined style={{ marginRight: '5px' }} alt='File type' />
        <Text copyable className="footer-whole-slide-image-name">
          {`${scanInfo.anonymization_name}`}
        </Text>
        <Divider type='vertical' />
        <BgColorsOutlined style={{ marginRight: '5px' }} />
        <Text>{scanInfo.staining.name}</Text>
        <Divider type='vertical' />
        <DeploymentUnitOutlined style={{ marginRight: '5px' }} />
        <Text>{scanInfo.organ?.name}</Text>
        <Divider type='vertical' />
        <ExperimentOutlined style={{ marginRight: '5px' }} />
        <Text>{scanInfo.lab.name}</Text>
      </>
    );
  }

  if (jobId) {
    return (
      <Text copyable className="footer-whole-slide-image-name">
        {`${jobId}`}
      </Text>
    );
  }

  if (!jobId && image) {
    return <Text copyable>{image.name}</Text>;
  }

  return null;
}

export default FooterImageInfo