import React from 'react';
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from 'react-router';

import Login from 'components/Login';
import ResetPassword from 'components/ResetPassword';
import AppLayout from 'components/AppLayout';
import ImageGrid from 'components/ImageGrid';
import JobLayout from 'components/JobLayout';
import ImageQALayout from 'components/ImageQALayout';
import JobOutputTable from 'components/JobOutputsTable';
import { ImageTable } from 'components/ImageTable';
import PipelineTable from 'components/PipelineTable';
import PipelineGrid from 'components/PipelineGrid';
import BacklogGrid from 'components/BacklogGrid';
import { getCurrentUser } from 'utils/Utils';
import JobTable from 'components/JobTable';

function ProtectedRoutes() {
  return getCurrentUser() ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to="/login" replace />
  );
}

const router = createBrowserRouter([
  // Authentication Routes
  {
    path: 'login',
    element: <Login />,
  },
  {
    path: 'reset-password',
    element: <ResetPassword />,
  },

  // Protected Routes that require authentication
  {
    element: <ProtectedRoutes />,
    children: [
      // Redirect root to /jobs
      {
        index: true,
        element: <Navigate to="/jobs" replace />,
      },

      // Job-related routes
      {
        path: 'jobs',
        children: [
          { index: true, element: <JobTable /> },
          { path: ':jobId', element: <JobLayout /> },
        ],
      },

      // Pipeline-related routes
      {
        path: 'pipelines',
        children: [
          { index: true, element: <PipelineTable /> },
          { path: 'grid', element: <PipelineGrid /> },
          { path: 'create', element: <ImageTable /> },
          { path: ':pipelineId', element: <PipelineTable /> },
        ],
      },

      // Backlogs and annotation outputs
      {
        path: 'backlogs',
        element: <BacklogGrid />,
      },
      {
        path: 'annotation-outputs',
        element: <JobOutputTable />,
      },

      // Image-related routes
      {
        path: 'images',
        children: [
          { index: true, element: <ImageGrid /> },
          { path: ':imageId', element: <ImageQALayout /> },
        ],
      },
    ],
  },

  // Fallback for unmatched paths
  {
    path: '*',
    element: <Navigate to="/" replace />,
  },
]);

export default function AppRouter() {
  return <RouterProvider router={router} />;
}
