import intersect from '@turf/intersect';
import booleanWithin from '@turf/boolean-within';
import { polygon, feature as TurfFeature, polygon as TurfPolygon, featureCollection } from '@turf/helpers';
import difference from '@turf/difference';
import { v4 as uuidv4 } from 'uuid';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createAnnotationEvent, generateAnnotationName, getRoundedPolygonPixels, isUserPathologist } from 'utils/Utils';
import {
  selectActiveAnnotationClassification,
  selectIsAnnotatingEnabled,
  selectIsMeasurementToolActive,
  setIsMeasurementToolActive,
} from 'redux/slices/viewerOptions';
import {
  appendToChangeset,
  selectSelectedAnnotationIds,
  setSelectedAnnotationIds,
} from 'redux/slices/annotationDetails';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { OL_LAYER_NAME } from 'utils/Constants';
import { Collection, Feature } from 'ol';
import { notification } from 'antd';
import { Polygon } from 'ol/geom';
import { Snap } from 'ol/interaction';
import { Layer } from 'ol/layer';
import { simplifyMultiPolygon } from 'utils/ol';
import VectorSource from 'ol/source/Vector';
import { useImage, usePolygons } from 'redux/hooks';
import { useGetImageInfoQuery } from 'redux/slices/api';

interface PolygonDrawInteractionProps {
  source: VectorSource;
};

export default function PolygonDrawInteraction({ source }: PolygonDrawInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);
  const { image } = useImage();
  const { data: imageInfo } = useGetImageInfoQuery(`${image!.name}${image!.file_type}`, { skip: !image })
  const { polygons } = usePolygons();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const isMeasurementToolActive = useSelector(selectIsMeasurementToolActive);
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);

  const drawPolygonAnnotationRef = useRef<Draw | null>(null);
  const polygonAnnotationBeingDrawn = useRef<Feature | null>(null);
  const snapToBorderRef = useRef<Snap | null>(null);

  const handleDrawPolygonAnnotationRefStart = (event: DrawEvent) => {
    if (selectedAnnotationIds.length !== 0) return;
    event.feature.set('is_closed', false);
    polygonAnnotationBeingDrawn.current = event.feature;
  };

  const handleDrawPolygonAnnotationRefEnd = (event: DrawEvent) => {
    if (!map || !image || !imageInfo) return;


    const coordinates = (event.feature.getGeometry() as Polygon).getCoordinates();

    let selectionPolygon = polygon(coordinates);

    // We need this variable as it is not possible to remove an OpenLayers Feature from inside the 'drawend' event.
    // If doNotAddDrawnPolygon = true, we add a 'removeMe' property to the drawn feature and in the 'addfeature' event
    // of the OpenLayers source, we remove this newly drawn feature.
    let doNotAddDrawnPolygon = false;

    const effectedPolygons: Array<TurfFeature<TurfPolygon>> = [];

    source.getFeatures().forEach((sourceFeature: Feature) => {
      if (sourceFeature.get('is_filtered')) return;

      const sourceFeatureCoordinates = (sourceFeature.getGeometry() as Polygon).getCoordinates();
      const sourceFeaturePolygon = polygon(sourceFeatureCoordinates);

      // If existing polygon is completely inside selectionPolygon, we don't need to check for intersection/differences
      if (booleanWithin(sourceFeaturePolygon, selectionPolygon)) {
        // If existing polygon is completely within selectionPolygon and has same class, remove newly drawn feature.
        if (sourceFeature.get('classification') === activeAnnotationClassification) {
          doNotAddDrawnPolygon = true;
        }
        return;
      }

      let polygonIntersection = intersect(featureCollection([selectionPolygon, sourceFeaturePolygon]));
      if (!polygonIntersection) return;
      effectedPolygons.push(sourceFeaturePolygon);
    });

    effectedPolygons.forEach(effectedPolygon => {
      const polygonDifference = difference(featureCollection([selectionPolygon, effectedPolygon]));
      if (!polygonDifference) return;

      if (polygonDifference.geometry.type === 'MultiPolygon') {
        selectionPolygon = polygon(polygonDifference.geometry.coordinates[0]);
      } else {
        selectionPolygon = polygon(polygonDifference.geometry.coordinates);
      }
    });

    if (doNotAddDrawnPolygon) {
      event.feature.set('removeMe', true);
      notification['error']({
        message: 'Polygons with the same classification cannot fully overlap',
        description: 'Please change classifications and re-draw the Polygon',
        duration: 10,
      });
      return;
    }

    const imagePolygon = polygon([
      [
        [0, 0],
        [imageInfo.width, 0],
        [imageInfo.width, -imageInfo.height],
        [0, -imageInfo.height],
        [0, 0],
      ],
    ]);

    let intersectionPolygon = intersect(featureCollection([selectionPolygon, imagePolygon]));

    // If selection polygon is fully inside Image polygon, do not make any adjustments to selection polygon
    if (!booleanWithin(selectionPolygon, imagePolygon)) {
      if (intersectionPolygon) {
        if (intersectionPolygon.geometry.type === 'MultiPolygon') {
          selectionPolygon = simplifyMultiPolygon(intersectionPolygon.geometry);
          selectionPolygon = polygon(selectionPolygon.geometry.coordinates);
        } else {
          selectionPolygon = polygon(intersectionPolygon.geometry.coordinates);
        }
      }
    }

    polygonAnnotationBeingDrawn.current = null;

    dispatch(
      appendToChangeset(
        createAnnotationEvent({
          type: 'ADD_POLYGON', payload: {
            id: uuidv4(),
            name: generateAnnotationName(polygons, 'p'),
            class_id: activeAnnotationClassification,
            coordinates: getRoundedPolygonPixels(selectionPolygon.geometry.coordinates),
            is_reviewed: isUserPathologist()
          }
        })
      ),
    );
    dispatch(setSelectedAnnotationIds([]));
  };

  const __handleKeyDown = (event: KeyboardEvent) => {
    if (!map || !image || !imageInfo) return;
    switch (event.key) {
      case 'Escape':
        if (drawPolygonAnnotationRef.current) {
          drawPolygonAnnotationRef.current.abortDrawing();
        }
        polygonAnnotationBeingDrawn.current = null;
        dispatch(setSelectedAnnotationIds([]));
        break;
      case 'Backspace':
        if (drawPolygonAnnotationRef.current) {
          drawPolygonAnnotationRef.current.removeLastPoint();
        }
        break;
      case 'x':
        if (drawPolygonAnnotationRef.current) {
          drawPolygonAnnotationRef.current.abortDrawing();
        }
        polygonAnnotationBeingDrawn.current = null;
        dispatch(setIsMeasurementToolActive(false));
        break;
      case 's':
        if (snapToBorderRef.current) break;

        const snapFeature = new Feature({
          geometry: new Polygon([
            [
              [0, 0],
              [imageInfo.width, 0],
              [imageInfo.width, -imageInfo.height],
              [0, -imageInfo.height],
              [0, 0],
            ],
          ]),
        });
        snapToBorderRef.current = new Snap({ features: new Collection([snapFeature]) });
        map!.addInteraction(snapToBorderRef.current);
        break;
      default:
        break;
    }
  };

  const __handleKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 's':
        if (snapToBorderRef.current) {
          map!.removeInteraction(snapToBorderRef.current);
          snapToBorderRef.current = null;
        }
        break;
      default:
        break;
    }
  };

  const initDraw = () => {
    return new Draw({
      source,
      type: 'Polygon',
      condition: e => {
        return e.originalEvent.button === 0;
      },
      finishCondition: event => {
        if (!polygonAnnotationBeingDrawn.current) return false;

        const polygonFirstCoordinatePx = map.getPixelFromCoordinate(
          (polygonAnnotationBeingDrawn.current.getGeometry() as Polygon).getFirstCoordinate(),
        );
        return !(
          event.pixel[0] - 10 >= polygonFirstCoordinatePx[0] ||
          event.pixel[0] + 10 <= polygonFirstCoordinatePx[0] ||
          event.pixel[1] - 10 >= polygonFirstCoordinatePx[1] ||
          event.pixel[1] + 10 <= polygonFirstCoordinatePx[1]
        );
      },
    });
  };

  window.polygonAnnotationSource = source;

  useEffect(() => {
    if (
      !map ||
      !source ||
      !isAnnotatingEnabled ||
      !activeAnnotationClassification ||
      isMeasurementToolActive
    )
      return;

    drawPolygonAnnotationRef.current = initDraw();

    drawPolygonAnnotationRef.current.on('drawstart', handleDrawPolygonAnnotationRefStart);
    drawPolygonAnnotationRef.current.on('drawend', handleDrawPolygonAnnotationRefEnd);

    source.on('addfeature', (event: DrawEvent) => {
      if (event.feature.get('removeMe')) {
        source.removeFeature(event.feature);
      }
    });

    map.addInteraction(drawPolygonAnnotationRef.current);

    document.addEventListener('keydown', __handleKeyDown);

    document.addEventListener('keyup', __handleKeyUp);

    return () => {
      if (map) {
        if (drawPolygonAnnotationRef.current) {
          drawPolygonAnnotationRef.current.un('drawstart', handleDrawPolygonAnnotationRefStart);
          drawPolygonAnnotationRef.current.un('drawend', handleDrawPolygonAnnotationRefEnd);
          map.removeInteraction(drawPolygonAnnotationRef.current);
        }

        document.removeEventListener('keydown', __handleKeyDown);
        document.removeEventListener('keyup', __handleKeyUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAnnotatingEnabled,
    map,
    imageInfo,
    activeAnnotationClassification,
    isMeasurementToolActive,
    selectedAnnotationIds,
  ]);

  useEffect(() => {
    if (!map || !isMeasurementToolActive) return;

    if (drawPolygonAnnotationRef.current) {
      drawPolygonAnnotationRef.current.un('drawstart', handleDrawPolygonAnnotationRefStart);
      drawPolygonAnnotationRef.current.un('drawend', handleDrawPolygonAnnotationRefEnd);
      map.removeInteraction(drawPolygonAnnotationRef.current);
    }

    document.removeEventListener('keydown', __handleKeyDown);
    document.removeEventListener('keyup', __handleKeyUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isMeasurementToolActive]);

  useEffect(() => {
    if (!map) return;

    if (drawPolygonAnnotationRef.current) {
      map.removeInteraction(drawPolygonAnnotationRef.current);
      drawPolygonAnnotationRef.current = initDraw();
      map.addInteraction(drawPolygonAnnotationRef.current);
      drawPolygonAnnotationRef.current.on('drawstart', handleDrawPolygonAnnotationRefStart);
      drawPolygonAnnotationRef.current.on('drawend', handleDrawPolygonAnnotationRefEnd);
    }

    polygonAnnotationBeingDrawn.current = null;

    return () => {
      // Abort drawing when component is unmounting
      drawPolygonAnnotationRef.current?.abortDrawing();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotationIds]);

  return null;
}
