import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { combineChangesetEntries } from 'utils/Utils';
import { RootState } from 'redux/store';
import { AnnotationEventSchema } from 'types';

type WSIRegion = {
  coordinates: number[];
  unit: string;
};

interface AnnotationDetails {
  allowSaving: boolean;
  changeset: Array<AnnotationEventSchema>;
  changesetPointer: number | null;
  featureToRemoveId: string;
  currentCaseName: string | null;
  currentJobId: string | null;
  selectedAnnotationIds: Array<string>;
  shouldCheckPolygons: boolean;
  isCheckingForPolygonIssues: boolean;
  polygonIssues: Array<any>;
  selectedPolygonIssue: any;
  hiddenAnnotationId: string | null;
  wsiRegion: WSIRegion | null;
  isUpdatingWSIRegion: boolean;
}

const initialState = {
  allowSaving: false,
  assignments: {
    entities: {
      cases: {},
      hotspots: {},
      imageAnnotations: {},
      images: {},
    },
    jobs: []
  },
  dotAnnotations: {},
  polygonAnnotations: {},
  changeset: [],
  changesetPointer: null,
  featureToRemoveId: '',
  currentCaseName: null,
  currentJobId: null,
  selectedAnnotationIds: [],
  shouldCheckPolygons: false,
  isCheckingForPolygonIssues: true,
  polygonIssues: [],
  selectedPolygonIssue: null,
  hiddenAnnotationId: null,
  wsiRegion: null,
  isUpdatingWSIRegion: false,
} as AnnotationDetails;

export const annotationDetails = createSlice({
  name: 'annotationDetails',
  initialState,
  reducers: {
    removeAllAnnotations: state => {
      state.dotAnnotations = {};
    },
    appendToChangeset: (state, action: PayloadAction<AnnotationEventSchema>) => {
      if (state.changesetPointer !== null) {
        state.changeset = state.changeset.slice(0, state.changesetPointer);
      }
      state.changeset.push(action.payload);
      state.changesetPointer = null;
    },
    extendChangeset: (state, action: PayloadAction<AnnotationEventSchema[]>) => {
      if (state.changesetPointer !== null) {
        state.changeset = state.changeset.slice(0, state.changesetPointer);
      }
      state.changeset = [...state.changeset, ...action.payload];
      state.changesetPointer = null;
    },
    clearChangeset: state => {
      state.changeset = [];
    },
    incrementChangesetPointer: state => {
      if (state.changesetPointer === null) return;
      if (state.changesetPointer === state.changeset.length) state.changesetPointer = null;
      if (state.changesetPointer) {
        state.changesetPointer += 1;
      } else {
        state.changesetPointer = 1;
      }
    },
    decrementChangesetPointer: state => {
      if (state.changesetPointer === 0) return;
      if (state.changesetPointer === null) state.changesetPointer = state.changeset.length;
      state.changesetPointer -= 1;
    },
    setCurrentCaseName: (state, action) => {
      state.currentCaseName = action.payload;
    },
    setCurrentJobId: (state, action) => {
      state.currentJobId = action.payload;
    },
    clearAnnotationDetails: () => initialState,
    setSelectedAnnotationIds: (state, action) => {
      state.selectedAnnotationIds = action.payload;
    },
    appendToSelectedAnnotationIds: (state, action: PayloadAction<Array<string>>) => {
      action.payload.forEach(annotationId => {
        if (!state.selectedAnnotationIds.includes(annotationId)) {
          state.selectedAnnotationIds.push(annotationId);
        }
      });
    },
    removeFromSelectedAnnotationIds: (state, action) => {
      state.selectedAnnotationIds = state.selectedAnnotationIds.filter(annotationId => annotationId !== action.payload);
    },
    setShouldCheckPolygons: (state, action) => {
      state.shouldCheckPolygons = action.payload;
    },
    setIsCheckingForPolygonIssues: (state, action) => {
      state.isCheckingForPolygonIssues = action.payload;
    },
    setPolygonIssues: (state, action) => {
      state.polygonIssues = action.payload;
    },
    setSelectedPolygonIssue: (state, action) => {
      state.selectedPolygonIssue = action.payload;
    },
    setHiddenAnnotationId: (state, action) => {
      state.hiddenAnnotationId = action.payload;
    },
    setWSIRegion: (state, action: PayloadAction<WSIRegion | null>) => {
      state.wsiRegion = action.payload;
    },
    setIsUpdatingWSIRegion: (state, action) => {
      state.isUpdatingWSIRegion = action.payload;
    },
  },
});

export const {
  removeAllAnnotations,
  appendToChangeset,
  extendChangeset,
  clearChangeset,
  incrementChangesetPointer,
  decrementChangesetPointer,
  setCurrentCaseName,
  setCurrentJobId,
  clearAnnotationDetails,
  setSelectedAnnotationIds,
  appendToSelectedAnnotationIds,
  removeFromSelectedAnnotationIds,
  setShouldCheckPolygons,
  setIsCheckingForPolygonIssues,
  setPolygonIssues,
  setSelectedPolygonIssue,
  setHiddenAnnotationId,
  setWSIRegion,
  setIsUpdatingWSIRegion,
} = annotationDetails.actions;

export const selectChangeset = (state: RootState) => state.annotationDetails.changeset;
export const selectChangesetPointer = (state: RootState) => state.annotationDetails.changesetPointer;
export const selectCurrentJobId = (state: RootState) => state.annotationDetails.currentJobId;
export const selectCurrentCaseName = (state: RootState) => state.annotationDetails.currentCaseName;
export const selectSelectedAnnotationIds = (state: RootState) => state.annotationDetails.selectedAnnotationIds;
export const selectShouldCheckPolygons = (state: RootState) => state.annotationDetails.shouldCheckPolygons;
export const selectIsCheckingForPolygonIssues = (state: RootState) =>
  state.annotationDetails.isCheckingForPolygonIssues;
export const selectPolygonIssues = (state: RootState) => state.annotationDetails.polygonIssues;
export const selectSelectedPolygonIssue = (state: RootState) => state.annotationDetails.selectedPolygonIssue;
export const selectHiddenAnnotationId = (state: RootState) => state.annotationDetails.hiddenAnnotationId;
export const selectWSIRegion = (state: RootState) => state.annotationDetails.wsiRegion;
export const selectIsUpdatingWSIRegion = (state: RootState) => state.annotationDetails.isUpdatingWSIRegion;

export const selectChangesetWithPointer = createSelector(
  [selectChangeset, selectChangesetPointer],
  (changeset, changesetPointer) => {
    if (changesetPointer === null) return changeset;
    return changeset.slice(0, changesetPointer);
  },
);

export const selectSimplifiedChangeset = createSelector([selectChangesetWithPointer], changeset => {
  const changesByObjectId = changeset.reduce((changesByObjectId: { [id: string]: ChangesetItem | null }, change) => {
    const previousChange = changesByObjectId[change.id];
    changesByObjectId[change.id] = combineChangesetEntries(previousChange, change);
    return changesByObjectId;
  }, {});
  return Object.values(changesByObjectId).filter(change => change !== null) as Array<ChangesetItem>;
});

export default annotationDetails.reducer;
