import { Badge, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useBlocker } from 'react-router';
import {
  clearChangeset,
  selectChangesetWithPointer,
  setSelectedAnnotationIds,
  setWSIRegion,
} from 'redux/slices/annotationDetails';
import {
  selectIsSidebarCollapsed,
  setActiveAnnotationClassification,
  setActiveReviewAnnotation,
  setActiveReviewRowIndex,
  resetAnnotationFilters,
  setAnnotationReviewFilters,
  setIsAnnotatingEnabled,
  setIsAnnotationsVisible,
  setIsSidebarCollapsed,
  setJumpToLocation,
  setShowWSIRegion,
} from 'redux/slices/viewerOptions';
import './style.css';
import CustomPageHeader from 'components/CustomPageHeader';
import { ClassificationSchema, AnnotationsSnapshotDict, JobSchema } from 'redux/slices/imageServerApi';
import BaseChangesetTable from 'components/ChangesetTables/BaseChangesetTable';
import AnnotationTable from 'components/AnnotationTables/AnnotationTable';
import { useComments } from 'redux/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import JobInfo from './JobInfo';
import { useCallback, useEffect } from 'react';

interface JobSidebarProps {
  job: JobSchema;
  annotations: AnnotationsSnapshotDict;
  classifications: Array<ClassificationSchema>;
}

const JobSidebar: React.FC<JobSidebarProps> = ({ job, annotations, classifications }) => {
  const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);
  const changeset = useSelector(selectChangesetWithPointer);

  const { comments } = useComments();
  const dispatch = useDispatch();

  const onCollapse = () => {
    dispatch(setIsSidebarCollapsed(!isSidebarCollapsed));
  };

  const leaveJob = useCallback(() => {
    dispatch(clearChangeset());
    dispatch(setIsAnnotatingEnabled(false));
    dispatch(resetAnnotationFilters());
    dispatch(setAnnotationReviewFilters([]));
    dispatch(setSelectedAnnotationIds([]));
    dispatch(setJumpToLocation(''));
    dispatch(setActiveReviewAnnotation(''));
    dispatch(setActiveReviewRowIndex(0));
    dispatch(setAnnotationReviewFilters([]));
    dispatch(setActiveAnnotationClassification(null));
    dispatch(setIsAnnotationsVisible(true));
    dispatch(setIsSidebarCollapsed(false));
    dispatch(setWSIRegion(null));
    dispatch(setShowWSIRegion(false));
  }, [dispatch]);

  // Clear job data when navigating away from a job
  useEffect(() => {
    return () => {
      leaveJob();
    }
  }, [leaveJob]);

  // Handle browser/tab closing
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (changeset.length > 0) {
        e.preventDefault();
        e.returnValue = ''; // Necessary for modern browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [changeset]);

  // Handle in-app navigation
  useBlocker(({ currentLocation, nextLocation }) => {
    if (changeset.length > 0) {
      const confirmLeave = window.confirm(
        'You have unsaved changes. Do you really want to leave this page?'
      );
      if (confirmLeave) {
        return false; // Allow navigation
      }
      return true; // Block navigation
    }
    return false; // Allow navigation if no unsaved changes
  });

  return (
    <>
      <CustomPageHeader
        title={job.id}
        isCollapsed={isSidebarCollapsed}
        onCollapse={onCollapse}
      />
      <div id="hotspot-details">
        {!isSidebarCollapsed &&
          <Tabs
            defaultActiveKey="1"
            size='small'
            type="card"
            items={[
              {
                key: '1',
                label: 'Job Info',
                children: <JobInfo job={job} />,
              },
              {
                key: '2',
                label: <span><Badge
                  size="small"
                  offset={[-3, -1]}
                  count={changeset.length !== 0 ? <ExclamationCircleOutlined style={{ color: "red" }} /> : 0}
                /> Changeset</span>,
                children: <BaseChangesetTable job={job} changeset={changeset} classifications={classifications} />,
              },
              {
                key: '3',
                label: <span><Badge
                  size="small"
                  offset={[-3, -1]}
                  count={comments.filter(c => !c.is_resolved).length !== 0 ? <ExclamationCircleOutlined style={{ color: "red" }} /> : 0}
                /> Annotations</span>,
                children: <AnnotationTable
                  annotations={annotations}
                  classifications={classifications}
                  task={job.task}
                />,
              },
            ]}
          ></Tabs>
        }
      </div>
    </>
  );
}

export default JobSidebar;
