import { Layout, Spin } from 'antd';

import JobSidebar from 'components/JobSidebar';
import ViewerOptions from 'components/ViewerOptions';
import OpenLayersMapComposition from 'components/OpenLayersMapComposition';
import ViewerFooter from 'components/Footer';
import { useSelector } from 'react-redux';
import { selectIsSidebarCollapsed } from 'redux/slices/viewerOptions';
import { useGetPipelineQuery, useGetClassificationScenarioQuery } from 'redux/slices/imageServerApi';

import "./style.css"
import { useAnnotations, useImage, useJob } from 'redux/hooks';

const { Sider } = Layout;


const JobLayout: React.FC = () => {
    const isSidebarCollapsed = useSelector(selectIsSidebarCollapsed);

    const { job } = useJob();
    const { image } = useImage();
    const { data: pipeline } = useGetPipelineQuery({ id: job?.pipeline }, { skip: !job });
    const { data: classificationScenario } = useGetClassificationScenarioQuery({ id: pipeline?.classification_scenario }, { skip: !pipeline });

    const { annotations } = useAnnotations();

    if (!image || !annotations || !pipeline || !classificationScenario || !job) return null;

    return (
        <Layout>
            {
                job && <Sider
                    collapsible
                    collapsed={isSidebarCollapsed}
                    className="annotation-tool-sider"
                    collapsedWidth={80}
                    width={360}
                    trigger={null}
                    theme="light"
                    style={{ borderRight: '1px solid #d9d9d9' }}
                >
                    <JobSidebar job={job} annotations={annotations} classifications={classificationScenario.classifications} />
                </Sider>
            }

            <Layout style={{ overflow: 'auto' }} className="annotation-tool-layout-content">
                <ViewerOptions job={job} annotations={annotations} pipeline={pipeline} />
                <OpenLayersMapComposition image={image} job={job} annotations={annotations} />
                <ViewerFooter image={image} classifications={classificationScenario?.classifications} />
            </Layout>
        </Layout>
    )
}

export default JobLayout