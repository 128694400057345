import { Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import type { ColumnType } from 'antd/es/table';

export const createSearchFilter = (placeholder: string) => {
  const filterDropdown: ColumnType<any>['filterDropdown'] = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <input
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onKeyDown={(e) => e.key === 'Enter' && confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  );

  const filterIcon: ColumnType<any>['filterIcon'] = (filtered?: boolean) => (
    <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
  );

  return { filterDropdown, filterIcon };
};
