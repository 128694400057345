import React, { useEffect, useRef, useState } from 'react';
import { Table, TableColumnsType, Button, Row, Col, Tooltip, Tag, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router';
import { useGetUsersQuery, useGetJobsQuery, useGetTasksQuery, useClaimJobMutation, GetJobsApiArg, ImageSchema, useGetStainingsQuery, useGetOrgansQuery, useGetClassificationScenarioQuery, useGetClassificationScenariosQuery, StainingSchema } from 'redux/slices/imageServerApi';
import { JobSchema } from 'redux/slices/imageServerApi';
import { CheckCircleOutlined, SyncOutlined, UserOutlined, EditOutlined, CalendarOutlined, PlusOutlined, EllipsisOutlined } from '@ant-design/icons';
import { getCurrentUser, isUserAdmin, isUserAnnotationManager } from 'utils/Utils';
import WSIThumbnail from 'components/WSIThumbnail';
import { format } from 'date-fns';
import { useFilters } from 'redux/hooks';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib';

const { Text } = Typography;

const JobTable: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useFilters<GetJobsApiArg>();
    const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
    const [sortField, setSortField] = useState<string | null>(null);

    const { data: users, isFetching: isFetchingUsers } = useGetUsersQuery({}, { skip: !isUserAnnotationManager() });
    const { data: tasks, isFetching: isFetchingTasks } = useGetTasksQuery({});
    const { data: stainings } = useGetStainingsQuery({}, { skip: !isUserAdmin() });
    const { data: organs } = useGetOrgansQuery({}, { skip: !isUserAdmin() });
    const { data: classificationScenarios } = useGetClassificationScenariosQuery();
    const { data: jobs, isFetching: isFetchingJobs, refetch } = useGetJobsQuery({ page, ...filters });
    const [claimJob] = useClaimJobMutation();

    const highlightedJobId = searchParams.get('highlight');
    const highlightedRowRef = useRef<HTMLTableRowElement | null>(null);
    const isFetching = isFetchingJobs || isFetchingTasks || isFetchingUsers;

    useEffect(() => {
        // Scroll to the highlighted row if it exists
        if (highlightedRowRef.current) {
            highlightedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [jobs]); // Ensure this runs after jobs are loaded

    useEffect(() => {
        const currentUsername = getCurrentUser()?.username;
        if (!currentUsername) return;
        setFilters({ ...filters, username: [currentUsername] })
    }, [users]);

    const availableUsers = isUserAdmin() ? users : [getCurrentUser()];

    const columns: TableColumnsType<JobSchema> = [
        {
            title: 'Job ID',
            dataIndex: 'id',
            key: 'id',
            render: (jobId: string) => <Text copyable>{jobId}</Text>
        },
        {
            title: 'Thumbnail',
            dataIndex: 'image',
            key: 'image',
            render: (image: ImageSchema) => (
                <Tooltip title="Click to view larger image">
                    <WSIThumbnail image={image} height={50} key={image.id} lazy={false} />
                </Tooltip>
            ),
        },
        {
            title: 'User',
            dataIndex: 'username',
            key: 'username',
            filters: availableUsers?.map(u => ({ text: u.username, value: u.username })),
            filteredValue: filters.username,
            filterMultiple: true,
            filterSearch: true,
            render: (username) => {
                if (!username) return null;
                return (
                    <Tag icon={<UserOutlined />} color="geekblue">
                        {username}
                    </Tag>
                )
            },
        },
        {
            title: 'Task',
            dataIndex: 'task',
            key: 'task',
            filters: tasks?.map(t => ({ text: t.name, value: t.name })),
            filteredValue: filters.task,
            filterMultiple: true,
            render: (task) => (
                <Tag icon={<EditOutlined />} color="magenta">
                    {task}
                </Tag>
            ),
        },
        {
            title: 'Class Scenario',
            dataIndex: 'classification_scenario',
            key: 'classificationScenario',
            filters: classificationScenarios?.map(c => ({ text: c.name, value: c.id })),
            filteredValue: filters.classificationScenario,
            filterMultiple: true,
            render: classScenarioId => classificationScenarios?.find(c => c.id === classScenarioId)?.name,
        },
        {
            title: 'Created Date',
            dataIndex: 'created',
            key: 'created',
            render: (created) => {
                if (!created) return null;
                return (
                    <Tooltip title={format(new Date(created), 'yyyy-MM-dd HH:mm:ss')}>
                        <Tag icon={<CalendarOutlined />} color="blue">
                            {format(new Date(created), 'yyyy-MM-dd')}
                        </Tag>
                    </Tooltip>
                )
            },
            sorter: true
        },
        {
            title: 'Assignment Date',
            dataIndex: 'assigned_at',
            key: 'assigned_at',
            render: (assignedAt) => {
                if (!assignedAt) return null;
                return (
                    <Tooltip title={format(new Date(assignedAt), 'yyyy-MM-dd HH:mm:ss')}>
                        <Tag icon={<CalendarOutlined />} color="blue">
                            {format(new Date(assignedAt), 'yyyy-MM-dd')}
                        </Tag>
                    </Tooltip>
                )
            },
            sorter: true
        },
        {
            title: 'Previous users',
            dataIndex: 'previously_involved_usernames',
            key: 'previousUser',
            filters: users?.map(u => ({ text: u.username, value: u.username })),
            filteredValue: filters.previousUser,
            filterMultiple: true,
            filterSearch: true,
            render: (prevUsers: string[]) => prevUsers.map(prevUser => (
                <Tag icon={<UserOutlined />} color="geekblue">
                    {prevUser}
                </Tag>
            )),
        },
        {
            title: 'Staining',
            dataIndex: ['image', 'scan', 'staining', 'name'],
            key: 'staining',
            filters: stainings?.map(s => ({ text: s.name, value: s.id })),
            filteredValue: filters.staining,
            filterMultiple: true,
            filterSearch: true,
            // render: (stainings: StainingSchema[]) => stainings.map(s => s.name).join(),
        },
        {
            title: 'Organ',
            dataIndex: ['image', 'scan', 'organ', 'name'],
            key: 'organ',
            filters: organs?.map(o => ({ text: o.name, value: o.id })),
            filteredValue: filters.organ,
            filterMultiple: true,
            filterSearch: true,
        },
        {
            title: 'Status',
            dataIndex: 'is_completed',
            key: 'isCompleted',
            filters: [{ text: 'WIP', value: false }, { text: 'Completed', value: true }],
            filteredValue: filters.isCompleted,
            render: (isCompleted) => (
                isCompleted ? (
                    <Tag icon={<CheckCircleOutlined />} color="green">
                        completed
                    </Tag>
                ) : (
                    <Tag icon={<SyncOutlined spin />} color="yellow">
                        WIP
                    </Tag>
                )
            ),
        },
    ];

    const handleTableChange = (pagination: TablePaginationConfig, filters: GetJobsApiArg, sorter: SorterResult<JobSchema>) => {
        setSortOrder(sorter.order ?? null);
        setSortField(sorter.field?.toString() || null);
        setPage(pagination.current || 1);
        setFilters(filters);
        refetch();
    };

    const handleClaimJob = async () => {
        await claimJob().unwrap();
        await refetch();
    };

    const handleGoToJob = (job: JobSchema) => {
        /**
         * Navigate to a job. We first set the highlight query param in order to highlight
         * the visited Job when pressing the back button.
         */
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.set('highlight', job.id);
        const newUrl = `${url.pathname}?${params.toString()}`;
        navigate(newUrl);
        navigate(`/jobs/${job.id}`);
    };

    return (
        <>
            <Row style={{ padding: '8px' }} justify="space-between">
                <Col>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={handleClaimJob}
                        disabled={!isUserAnnotationManager()}
                    >
                        Claim Annotation Job
                    </Button>
                </Col>
            </Row>

            <Table
                loading={isFetching}
                dataSource={jobs?.items}
                columns={columns}
                rowKey="id"
                pagination={{
                    current: page,
                    pageSize: 100,
                    total: jobs?.count ?? 0,
                    showTotal: (total) => `${total} Jobs`,
                }}
                onChange={handleTableChange}
                sticky
                onRow={(record) => ({
                    ref: record.id === highlightedJobId ? (node) => (highlightedRowRef.current = node) : undefined,
                    style: record.id === highlightedJobId ? { backgroundColor: '#f0f8ff' } : undefined,
                    onClick: () => {
                        handleGoToJob(record)
                    },
                })}
            />
        </>
    );
};

export default JobTable;
