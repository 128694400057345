import { useContext, useEffect, useRef } from 'react';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import VectorLayer from 'ol/layer/Vector';
import Stroke from 'ol/style/Stroke';
import Feature from 'ol/Feature';
import OLPolygon from 'ol/geom/Polygon';
import { useSelector } from 'react-redux';

import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';

import { OL_LAYER_NAME } from 'utils/Constants';
import VectorSource from 'ol/source/Vector';

import { ClassificationSchema, PolygonAnnotationDict } from 'redux/slices/imageServerApi';
import { selectAnnotationFilters } from 'redux/slices/viewerOptions';
import { checkIsFiltered } from 'utils/Utils';

interface PolygonAnnotationLayerProps {
  source: VectorSource<Feature<OLPolygon>>;
  polygonAnnotations: Array<PolygonAnnotationDict>;
  classifications: Array<ClassificationSchema>;
  visible?: boolean;
}

const PolygonAnnotationLayer: React.FC<PolygonAnnotationLayerProps> = ({ source, polygonAnnotations, classifications, visible = true }) => {
  const { map } = useContext(OpenLayersMapContext);

  const annotationFilters = useSelector(selectAnnotationFilters);
  const isLayerActive = map && classifications.length > 0;

  let polygonAnnotationLayer = useRef<PolygonAnnotationLayerProps['source'] | null>(null);
  const styles = useRef<PolygonAnnotationLayerStyles | null>(null);

  const initOLLayer = (styles: PolygonAnnotationLayerStyles) => {
    const polygonAnnotationLayer = new VectorLayer({
      className: 'polygon-annotation-layer',
      properties: { name: OL_LAYER_NAME.ANNOTATION_POLYGON },
      source,
      updateWhileAnimating: true,
      updateWhileInteracting: true,
      style:
        feature => {
          const classId = feature.get('class_id');
          const isReviewed = feature.get('is_reviewed');
          const isFiltered = feature.get('is_filtered');
          return [
            isReviewed
              ? isFiltered
                ? styles.strokes.reviewed_filtered
                : styles.strokes.reviewed
              : isFiltered
                ? styles.strokes.unreviewed_filtered
                : styles.strokes.unreviewed,
            isFiltered
              ? styles.classifications[`${classId}_filtered`]
              : styles.classifications[classId],
          ];
        },
    });
    polygonAnnotationLayer.setZIndex(1);
    return polygonAnnotationLayer;
  };

  const initOLStyles = (classifications: Array<ClassificationSchema>) => {
    const classStyles: { [name: string]: Style } = {};
    classifications.forEach(classification => {
      let strokeColor = classification.color ? classification.color : '#000000';
      classStyles[classification.id] = new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      });
      classStyles[`${classification.id}_no_snapshot`] = new Style({
        stroke: new Stroke({
          color: strokeColor,
          width: 3,
          lineDash: [8],
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      });
      classStyles[`${classification.id}_filtered`] = new Style({
        stroke: new Stroke({
          color: `${strokeColor}4D`,
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      });
      classStyles['split_line'] = new Style({
        stroke: new Stroke({
          color: '#000000',
          width: 3,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      });
    });
    const strokeStyles = {
      reviewed: new Style({
        stroke: new Stroke({
          color: '#000000',
          width: 7,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      }),
      reviewed_filtered: new Style({
        stroke: new Stroke({
          color: '#0000004D',
          width: 7,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      }),
      unreviewed: new Style({
        stroke: new Stroke({
          color: '#ffffff',
          width: 7,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      }),
      unreviewed_filtered: new Style({
        stroke: new Stroke({
          color: '#ffffff4D',
          width: 7,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.0)',
        }),
      }),
      snapshot: new Style({
        stroke: new Stroke({
          color: '#000000',
          width: 7,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0)',
        }),
      }),
      split_line: new Style({
        stroke: new Stroke({
          color: '#000000',
          width: 7,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0)',
        }),
      }),
    };
    return {
      classifications: classStyles,
      strokes: strokeStyles,
    };
  };

  useEffect(() => {
    if (!map || !isLayerActive || classifications.length === 0) return;
    styles.current = initOLStyles(classifications);
    polygonAnnotationLayer.current = initOLLayer(styles.current);
    map.addLayer(polygonAnnotationLayer.current);

    return () => {
      if (map) {
        if (polygonAnnotationLayer.current) {
          map.removeLayer(polygonAnnotationLayer.current);
        }
        if (source) {
          source.clear();
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, source, classifications]);

  useEffect(() => {
    if (!isLayerActive || !map || classifications.length === 0) return;
    styles.current = initOLStyles(classifications);
    if (polygonAnnotationLayer.current) {
      map.removeLayer(polygonAnnotationLayer.current);
    }
    polygonAnnotationLayer.current = initOLLayer(styles.current);
    map.addLayer(polygonAnnotationLayer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classifications]);

  useEffect(() => {
    if (!isLayerActive || !map || !polygonAnnotationLayer.current) return;
    polygonAnnotationLayer.current.setVisible(visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (!source || !polygonAnnotationLayer.current) return;
    polygonAnnotationLayer.current.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonAnnotations]);

  useEffect(() => {
    if (!isLayerActive || !source) return;
    source.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source]);

  useEffect(() => {
    if (!polygonAnnotations || polygonAnnotations.length === 0) {
      source.clear();
      source.changed();
      return;
    }

    const newPolygonAnnotations = polygonAnnotations.map(polygon => {
      const coordinates = polygon.coordinates.map(item => [item[0], -item[1]]);
      const newPolygonAnnotation = new Feature({
        geometry: new OLPolygon([coordinates]),
        class_id: polygon.class_id,
        name: polygon.name,
        is_closed: true,
        is_reviewed: true,
        last_editor: polygon.last_editor,
        is_filtered: checkIsFiltered(polygon, annotationFilters),
      });
      newPolygonAnnotation.setId(polygon.id);

      return newPolygonAnnotation;
    });

    source.clear();
    source.addFeatures(newPolygonAnnotations);
    source.changed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygonAnnotations, classifications, annotationFilters]);

  return null;
}

export default PolygonAnnotationLayer;