import * as Sentry from '@sentry/react';
import { configureStore, MiddlewareAPI, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { message } from 'antd';

import annotationDetailsReducer from 'redux/slices/annotationDetails';
import viewerOptionsReducer from 'redux/slices/viewerOptions';
import userReducer from 'redux/slices/userSlice';
import { imageReaderApi } from 'redux/slices/api'
import { imageServerApi } from 'redux/slices/imageServerApi';


const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// Define a middleware function
const messageToastMiddleware = (storeAPI: MiddlewareAPI) => (next: Dispatch<PayloadAction>) => (action: PayloadAction) => {
  // If action type ends with 'rejected'
  if (action.type.endsWith('rejected') && action?.error?.name !== 'ConditionError') {
    // Get error message from payload or set a default one
    const errorMessage = action.payload?.data?.message || action.payload?.data?.detail || 'An error occurred while fetching data.';
    console.error(errorMessage);
    message.error(errorMessage, );
  } else if (action.type.endsWith('executeMutation/fulfilled')) {
    const successMessage = action.payload?.message;
    message.success(successMessage || 'Success!')
  }
  // Pass action to next middleware in chain
  return next(action);
}

export const store = configureStore({
  reducer: {
    viewerOptions: viewerOptionsReducer,
    annotationDetails: annotationDetailsReducer,
    user: userReducer,
    [imageServerApi.reducerPath]: imageServerApi.reducer,
    [imageReaderApi.reducerPath]: imageReaderApi.reducer,
  },
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sentryReduxEnhancer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(messageToastMiddleware).concat(imageServerApi.middleware).concat(imageReaderApi.middleware),
  devTools: {
    actionsDenylist: ['viewerOptions/setMouseCoordinates'],
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
