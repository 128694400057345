import intersect from '@turf/intersect';
import booleanWithin from '@turf/boolean-within';
import { polygon, feature as TurfFeature, polygons as TurfPolygon, featureCollection } from '@turf/helpers';
import difference from '@turf/difference';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Select, { SelectEvent } from 'ol/interaction/Select';
import Modify, { ModifyEvent } from 'ol/interaction/Modify';
import { useContext, useEffect, useRef } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { createAnnotationEvent, getRoundedPolygonPixels } from 'utils/Utils';
import {
  selectActiveAnnotationClassification,
  selectAnnotationFilters,
  selectIsAnnotatingEnabled,
  selectIsMeasurementToolActive,
  setActiveAnnotationClassification,
  setScrollToAnnotationId,
} from 'redux/slices/viewerOptions';
import {
  appendToChangeset,
  selectSelectedAnnotationIds,
  setSelectedAnnotationIds,
} from 'redux/slices/annotationDetails';
import OpenLayersMapContext from 'components/OpenLayersMap/OpenLayersMapContext';
import { OL_LAYER_NAME } from 'utils/Constants';
import { Collection, Feature } from 'ol';
import { notification } from 'antd';
import { singleClick } from 'ol/events/condition';
import { Geometry, Polygon } from 'ol/geom';
import { Snap } from 'ol/interaction';
import { Layer } from 'ol/layer';
import { calculatePolygonArea, simplifyMultiPolygon } from 'utils/ol';
import VectorSource from 'ol/source/Vector';
import { useImage, usePolygons } from 'redux/hooks';
import { useGetImageInfoQuery } from 'redux/slices/api';

interface PolygonSelectModifyInteractionProps {
  source: VectorSource;
};

export default function PolygonSelectModifyInteraction({ source }: PolygonSelectModifyInteractionProps) {
  const { map } = useContext(OpenLayersMapContext);
  const dispatch = useDispatch();

  const activeAnnotationClassification = useSelector(selectActiveAnnotationClassification);
  const annotationFilters = useSelector(selectAnnotationFilters);
  const { image } = useImage();
  const { polygons } = usePolygons();
  const isAnnotatingEnabled = useSelector(selectIsAnnotatingEnabled);
  const isMeasurementToolActive = useSelector(selectIsMeasurementToolActive);
  const { data: imageInfo } = useGetImageInfoQuery(`${image!.name}${image!.file_type}`, { skip: !image })
  const selectedAnnotationIds = useSelector(selectSelectedAnnotationIds);

  const selectPolygonAnnotationRef = useRef<Select | null>(null);
  const modifyPolygonAnnotationRef = useRef<Modify | null>(null);
  const snapToBorderRef = useRef<Snap | null>(null);

  const handleDeleteSelectedPolygonAnnotation = () => {
    selectedAnnotationIds.forEach(selectedAnnotationId => {
      dispatch(
        appendToChangeset(createAnnotationEvent({
          type: 'DELETE_POLYGON',
          payload: {
            id: selectedAnnotationId
          }
        })),
      );

      if (selectPolygonAnnotationRef.current) {
        selectPolygonAnnotationRef.current.getFeatures().clear();
      }

      notification['info']({
        message: `Removed Polygon Annotation`,
      });

      dispatch(setSelectedAnnotationIds([]));
    });
  };

  const handleSelectPolygonAnnotation = (event: SelectEvent) => {
    if (event.deselected.length > 0) {
      dispatch(setSelectedAnnotationIds([]));
    }

    if (!event.selected[0]) return;

    let selectedFeature: Feature<Geometry> | null = null;
    let smallestArea = 0;

    if (event.selected.length > 1) {
      event.selected.forEach((feat: Feature<Geometry>) => {
        const featureGeometry = feat.getGeometry() as Polygon;
        const featureCoordinates = featureGeometry.getCoordinates();

        const polygonArea = calculatePolygonArea(polygon(featureCoordinates));
        if (!smallestArea || polygonArea < smallestArea) {
          smallestArea = polygonArea;
          selectedFeature = feat;
        }
      });
    } else {
      selectedFeature = event.selected[0];
    }

    if (!selectedFeature) return;

    if (selectedFeature.get('is_filtered') === 'true') return;
    const selectedFeatureId = selectedFeature.getId() as string;
    batch(() => {
      dispatch(setSelectedAnnotationIds([selectedFeatureId]));
      dispatch(setActiveAnnotationClassification(selectedFeature?.get('class_id')));
      dispatch(setScrollToAnnotationId(selectedFeatureId));
    });
  };

  const handleModifyPolygonAnnotationEnd = (event: ModifyEvent) => {
    if (!image || !imageInfo) return;

    const feature = event.features.getArray()[0];
    let coordinates = (feature.getGeometry() as Polygon).getCoordinates();
    let selectionPolygon = polygon(coordinates);
    let doNotModifyDrawnPolygon = false;
    let polygonsToModify: Array<{ id: string; selectionPolygon: TurfFeature<TurfPolygon> }> = [];
    const affectedPolygons: Array<TurfFeature<TurfPolygon>> = [];

    source.getFeatures().forEach((sourceFeature: Feature) => {
      if (sourceFeature.get('is_filtered') || feature.getId() === sourceFeature.getId()) return;

      const sourceFeatureCoordinates = (sourceFeature.getGeometry() as Polygon).getCoordinates();
      const sourceFeaturePolygon = polygon(sourceFeatureCoordinates);

      // If existing polygon is completely inside selectionPolygon, we don't need to check for intersection/differences
      if (booleanWithin(sourceFeaturePolygon, selectionPolygon)) {
        // If existing polygon is completely within selectionPolygon and has same class, remove newly drawn feature.
        if (sourceFeature.get('classification') === activeAnnotationClassification) {
          doNotModifyDrawnPolygon = true;
        }
        return;
      }

      let polygonIntersection = intersect(featureCollection([selectionPolygon, sourceFeaturePolygon]));
      if (!polygonIntersection) return;
      affectedPolygons.push(sourceFeaturePolygon);
    });

    affectedPolygons.forEach(effectedPolygon => {
      const polygonDifference = difference(featureCollection([selectionPolygon, effectedPolygon]));
      if (!polygonDifference) return;

      if (polygonDifference.geometry.type === 'MultiPolygon') {
        selectionPolygon = polygon(polygonDifference.geometry.coordinates[0]);
      } else {
        selectionPolygon = polygon(polygonDifference.geometry.coordinates);
      }
    });

    if (polygonsToModify.length === 0) {
      polygonsToModify.push({ id: feature.getId() as string, selectionPolygon });
    }

    if (doNotModifyDrawnPolygon) {
      notification['error']({
        message: 'Polygons with the same classification cannot fully overlap',
        description: 'Please change classifications and re-draw the Polygon',
        duration: 10,
      });
      return;
    }

    const imagePolygon = polygon([
      [
        [0, 0],
        [imageInfo.width, 0],
        [imageInfo.width, -imageInfo.height],
        [0, -imageInfo.height],
        [0, 0],
      ],
    ]);

    let intersectionPolygon = intersect(featureCollection([selectionPolygon, imagePolygon]));

    // If selection polygon is fully inside Image polygon, do not make any adjustments to selection polygon
    if (!booleanWithin(selectionPolygon, imagePolygon)) {
      if (intersectionPolygon) {
        if (intersectionPolygon.geometry.type === 'MultiPolygon') {
          selectionPolygon = simplifyMultiPolygon(intersectionPolygon.geometry);
          selectionPolygon = polygon(selectionPolygon.geometry.coordinates);
        } else {
          selectionPolygon = polygon(intersectionPolygon.geometry.coordinates);
        }
      }
    }

    dispatch(
      appendToChangeset(createAnnotationEvent({
        type: 'UPDATE_POLYGON',
        payload: {
          id: feature.getId(),
          coordinates: getRoundedPolygonPixels(selectionPolygon.geometry.coordinates),
        }
      })),
    );
  };

  const changeSelectedPolygonAnnotationsClassification = () => {
    if (selectedAnnotationIds.length > 1 || selectedAnnotationIds.length === 0) return;

    selectedAnnotationIds.forEach(selectedAnnotationId => {
      const feature = source.getFeatureById(selectedAnnotationId) as Feature;
      if (!feature || feature.get('class_id') === activeAnnotationClassification) return;
      dispatch(
        appendToChangeset(
          createAnnotationEvent({
            type: 'UPDATE_POLYGON',
            payload: {
              id: feature.getId(),
              class_id: activeAnnotationClassification,
            }
          })
        ),
      );
    });
  };

  const __handleKeyDown = (event: KeyboardEvent) => {
    if (!imageInfo) return;
    switch (event.key) {
      case 'Delete':
        handleDeleteSelectedPolygonAnnotation();
        break;
      case 's':
        if (snapToBorderRef.current) break;

        const snapFeature = new Feature({
          geometry: new Polygon([
            [
              [0, 0],
              [imageInfo.width, 0],
              [imageInfo.width, -imageInfo.height],
              [0, -imageInfo.height],
              [0, 0],
            ],
          ]),
        });
        snapToBorderRef.current = new Snap({ features: new Collection([snapFeature]) });
        map!.addInteraction(snapToBorderRef.current);
        break;
      default:
        break;
    }
  };

  const __handleKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 's':
        if (snapToBorderRef.current) {
          map!.removeInteraction(snapToBorderRef.current);
          snapToBorderRef.current = null;
        }
        break;
      default:
        break;
    }
  };

  const initSelect = () => {
    const selectedFeatures: Collection<Feature> = new Collection(
      source.getFeatures().filter((a: Feature) => selectedAnnotationIds.includes(a.getId() as string)),
    );

    return new Select({
      layers: (layer: Layer) => {
        return layer.get('name') === OL_LAYER_NAME.ANNOTATION_POLYGON;
      },
      condition: e => singleClick(e),
      multi: true,
      filter: feature => !feature.get('is_filtered'),
      features: selectedFeatures,
      style: [
        new Style({
          stroke: new Stroke({
            color: '#ffffff',
            width: 5,
          }),
        }),
        new Style({
          stroke: new Stroke({
            color: '#0099ff',
            width: 3,
          }),
        }),
      ],
    });
  };

  const initModify = () => {
    const selectedFeatures: Collection<Feature> = new Collection(
      source.getFeatures().filter((a: Feature) => selectedAnnotationIds.includes(a.getId() as string)),
    );
    return new Modify({
      features: selectedFeatures,
    });
  };

  window.polygonAnnotationSource = source;

  // Deselect all polygons when annotation filters change
  useEffect(() => {
    if (!selectPolygonAnnotationRef?.current) return;
    selectPolygonAnnotationRef.current.getFeatures().clear();
  }, [annotationFilters]);

  useEffect(() => {
    if (!activeAnnotationClassification || !selectPolygonAnnotationRef.current) return;
    changeSelectedPolygonAnnotationsClassification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAnnotationClassification]);

  useEffect(() => {
    if (
      !map ||
      !source ||
      !isAnnotatingEnabled ||
      isMeasurementToolActive
    )
      return;

    selectPolygonAnnotationRef.current = initSelect();
    modifyPolygonAnnotationRef.current = initModify();

    selectPolygonAnnotationRef.current.on('select', handleSelectPolygonAnnotation);

    modifyPolygonAnnotationRef.current.on('modifyend', handleModifyPolygonAnnotationEnd);


    map.addInteraction(selectPolygonAnnotationRef.current);

    map.addInteraction(modifyPolygonAnnotationRef.current);

    document.addEventListener('keydown', __handleKeyDown);

    document.addEventListener('keyup', __handleKeyUp);

    return () => {
      if (map) {
        if (selectPolygonAnnotationRef.current) {
          selectPolygonAnnotationRef.current.un('select', handleSelectPolygonAnnotation);
          map.removeInteraction(selectPolygonAnnotationRef.current);
        }

        if (modifyPolygonAnnotationRef.current) {
          modifyPolygonAnnotationRef.current.un('modifyend', handleModifyPolygonAnnotationEnd);
          map.removeInteraction(modifyPolygonAnnotationRef.current);
        }

        document.removeEventListener('keydown', __handleKeyDown);
        document.removeEventListener('keyup', __handleKeyUp);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAnnotatingEnabled,
    map,
    imageInfo,
    activeAnnotationClassification,
    isMeasurementToolActive,
    selectedAnnotationIds,
  ]);

  useEffect(() => {
    if (!map || !isMeasurementToolActive) return;

    if (selectPolygonAnnotationRef.current) {
      selectPolygonAnnotationRef.current.un('select', handleSelectPolygonAnnotation);
      map.removeInteraction(selectPolygonAnnotationRef.current);
    }

    if (modifyPolygonAnnotationRef.current) {
      modifyPolygonAnnotationRef.current.un('modifyend', handleModifyPolygonAnnotationEnd);
      map.removeInteraction(modifyPolygonAnnotationRef.current);
    }

    document.removeEventListener('keydown', __handleKeyDown);
    document.removeEventListener('keyup', __handleKeyUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, isMeasurementToolActive]);

  useEffect(() => {
    if (!map) return;

    if (selectPolygonAnnotationRef.current) {
      map.removeInteraction(selectPolygonAnnotationRef.current);
      selectPolygonAnnotationRef.current = initSelect();
      map.addInteraction(selectPolygonAnnotationRef.current);
      selectPolygonAnnotationRef.current.on('select', handleSelectPolygonAnnotation);
    }

    if (modifyPolygonAnnotationRef.current) {
      map.removeInteraction(modifyPolygonAnnotationRef.current);
      modifyPolygonAnnotationRef.current = initModify();
      modifyPolygonAnnotationRef.current.on('modifyend', handleModifyPolygonAnnotationEnd);
      map.addInteraction(modifyPolygonAnnotationRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotationIds]);

  return null;
}
