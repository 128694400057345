import { Button, Checkbox, Form, InputNumber, Popover, Select, Tooltip, Typography } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectIsUpdatingWSIRegion,
  selectWSIRegion,
  setWSIRegion,
} from 'redux/slices/annotationDetails';
import { useEffect, useState } from 'react';
import { Divider } from 'antd/lib';
import { DownCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { setShowWSIRegion } from 'redux/slices/viewerOptions';
import { useAppDispatch } from 'utils/Hooks';
import { useImage } from 'redux/hooks';
import { usePostWsiRegionMutation } from 'redux/slices/imageServerApi';

const { Option } = Select;
const { Text } = Typography;

const checkIfWSIRegionIsUnchanged = (
  initialValues: WSIRegionControlForm | undefined,
  currentValues: WSIRegionControlForm,
) => {
  if (!initialValues) return false;

  return (
    initialValues.x0 === currentValues.x0 &&
    initialValues.y0 === currentValues.y0 &&
    initialValues.x1 === currentValues.x1 &&
    initialValues.y1 === currentValues.y1 &&
    initialValues.unit === currentValues.unit
  );
};

const getWSIRegionStringFromCurrentValues = (currentValues: WSIRegionControlForm, includeUnit: boolean) => {
  if (
    (currentValues.x0 === 0 || currentValues.x0 === undefined) &&
    (currentValues.y0 === 0 || currentValues.y0 === undefined) &&
    (currentValues.x1 === 0 || currentValues.x1 === undefined) &&
    (currentValues.y1 === 0 || currentValues.y1 === undefined)
  )
    return 'Not set';

  let coordinateString = `${currentValues.x0}, ${currentValues.y0}, ${currentValues.x1}, ${currentValues.y1}`;
  if (includeUnit) {
    coordinateString += ` (${currentValues.unit})`;
  }
  return coordinateString;
};

type WSIRegionControlForm = {
  x0: number;
  y0: number;
  x1: number;
  y1: number;
  unit: string;
};

export default function WSIRegionControl() {
  const dispatch = useAppDispatch();
  const { image } = useImage();
  const [form] = Form.useForm();
  const isUpdatingWSIRegion = useSelector(selectIsUpdatingWSIRegion);
  const x0 = Form.useWatch('x0', form);
  const y0 = Form.useWatch('y0', form);
  const x1 = Form.useWatch('x1', form);
  const y1 = Form.useWatch('y1', form);
  const unit = Form.useWatch('unit', form);
  const wsiRegion = useSelector(selectWSIRegion);
  const [postWsiRegion, { isLoading: isPostingWsiRegion }] = usePostWsiRegionMutation();
  const [initialFormValues, setInitialFormValues] = useState<WSIRegionControlForm | undefined>(undefined);

  useEffect(() => {
    if (!image) return;
    if (image.wsi_region) {
      dispatch(setWSIRegion({ coordinates: image.wsi_region, unit: 'pixel' }))
    }
    setInitialFormValues({
      x0: image.wsi_region ? image.wsi_region[0] : 0,
      y0: image.wsi_region ? image.wsi_region[1] : 0,
      x1: image.wsi_region ? image.wsi_region[2] : 0,
      y1: image.wsi_region ? image.wsi_region[3] : 0,
      unit: image.wsi_region_unit ? image.wsi_region_unit : 'pixel',
    });
  }, [dispatch, image]);

  useEffect(() => {
    if (!form) return;
    if (wsiRegion) {
      form.setFieldValue('x0', wsiRegion.coordinates[0]);
      form.setFieldValue('y0', wsiRegion.coordinates[1]);
      form.setFieldValue('x1', wsiRegion.coordinates[2]);
      form.setFieldValue('y1', wsiRegion.coordinates[3]);
    } else {
      form.setFieldValue('x0', 0);
      form.setFieldValue('y0', 0);
      form.setFieldValue('x1', 0);
      form.setFieldValue('y1', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsiRegion]);

  if (!image) return null;

  const handleCheckboxOnChange = (event: CheckboxChangeEvent) => {
    dispatch(setShowWSIRegion(event.target.checked));
  };

  const handleSubmit = async () => {
    if (!image.id || !wsiRegion?.coordinates) return;
    await postWsiRegion({id: image.id, wsiRegion: wsiRegion.coordinates});
  };

  const handleFormChange = (changedValue: any, values: WSIRegionControlForm) => {
    const { x0, y0, x1, y1, unit } = values;
    dispatch(setWSIRegion({ coordinates: [x0, y0, x1, y1], unit }));
  };

  const wsiRegionIsUnchanged = checkIfWSIRegionIsUnchanged(initialFormValues, { x0, x1, y0, y1, unit });

  let wsiRegionString = getWSIRegionStringFromCurrentValues({ x0, x1, y0, y1, unit }, true);
  let copyableText = getWSIRegionStringFromCurrentValues({ x0, x1, y0, y1, unit }, false);

  if (wsiRegion) {
    wsiRegionString = getWSIRegionStringFromCurrentValues(
      {
        x0: wsiRegion.coordinates[0],
        y0: wsiRegion.coordinates[1],
        x1: wsiRegion.coordinates[2],
        y1: wsiRegion.coordinates[3],
        unit: wsiRegion.unit,
      },
      true,
    );
    copyableText = getWSIRegionStringFromCurrentValues(
      {
        x0: wsiRegion.coordinates[0],
        y0: wsiRegion.coordinates[1],
        x1: wsiRegion.coordinates[2],
        y1: wsiRegion.coordinates[3],
        unit: wsiRegion.unit,
      },
      false,
    );
  }

  const handleClearWSIRegionOnClick = () => {
    dispatch(setWSIRegion(null));
  };

  return (
    <div style={{ padding: '10px' }}>
      <Text>WSI Region: </Text>
      <Popover
        content={
          <Form
            form={form}
            initialValues={initialFormValues}
            layout="vertical"
            onFinish={handleSubmit}
            onValuesChange={handleFormChange}
          >
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Form.Item label="x0" name="x0">
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item label="y0" name="y0">
                <InputNumber min={0} />
              </Form.Item>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Form.Item label="x1" name="x1">
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item label="y1" name="y1">
                <InputNumber min={0} />
              </Form.Item>
            </div>
            <Form.Item
              label={
                <>
                  WSI Region Unit{' '}
                  <Tooltip title="Currently only the 'pixel' unit is supported">
                    <Button type="text" icon={<InfoCircleOutlined />} />
                  </Tooltip>
                </>
              }
              name="unit"
            >
              <Select
                id="wsi-region-unit-select"
                style={{ width: '220px', paddingLeft: '4px', paddingRight: '8px' }}
                disabled={true}
              >
                <Option key="pixel" value="pixel">
                  Pixel
                </Option>
                <Option key="percentage" value="percentage">
                  Percentage
                </Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleClearWSIRegionOnClick}
                style={{ width: '100%' }}
                disabled={!wsiRegion}
                danger
              >
                Clear WSI Region
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                style={{ width: '100%' }}
                loading={isUpdatingWSIRegion}
                disabled={wsiRegionIsUnchanged}
              >
                {isUpdatingWSIRegion ? 'Saving WSI Region' : 'Save WSI Region'}
              </Button>
            </Form.Item>
          </Form>
        }
        title="WSI Region"
        trigger="click"
        placement="leftBottom"
      >
        <Button style={{ marginLeft: '8px' }}>
          {wsiRegionString} <DownCircleOutlined />
        </Button>
      </Popover>
      <Text copyable={{ text: copyableText }} />
    </div>
  );
}
