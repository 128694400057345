import { Button, Tooltip, Typography } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

const { Title } = Typography;

/**
 * This replaces AntD's PageHeader which was removed in v5 of the library
 */
export default function CustomPageHeader({ title, isCollapsed, onCollapse, onBack }: CustomPageHeaderType) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '16px' }}>
      {isCollapsed ? null : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Title level={2} copyable style={{ margin: 0, fontSize: '14px'}}>
              {title}
            </Title>
        </div>
      )}
      {isCollapsed ? (
        <Tooltip key="menu_fold_tooltip" title="Maximise Sidebar">
          <Button shape="circle" icon={<MenuUnfoldOutlined />} onClick={onCollapse} />
        </Tooltip>
      ) : (
        <Tooltip key="menu_fold_tooltip" title="Minimise Sidebar">
          <Button shape="circle" icon={<MenuFoldOutlined />} onClick={onCollapse} />
        </Tooltip>
      )}
    </div>
  );
}
