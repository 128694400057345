import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, Alert, Checkbox } from 'antd';
import { CheckOutlined, RollbackOutlined } from '@ant-design/icons';
import { useGetUsersQuery, useGetTasksQuery, useGetGroupsQuery, JobSchema, useGetTaskQuery, useGetJobQuery, useCompleteJobMutation, PipelineSchema } from 'redux/slices/imageServerApi';
import { useNavigate } from 'react-router';
import { isUserAnnotationManager } from 'utils/Utils';

const { Option } = Select;

interface CompleteReassignJobModalProps {
    job: JobSchema;
    pipeline: PipelineSchema;
    isCompletingDisabled: boolean;
}

const CompleteReassignJobModal: React.FC<CompleteReassignJobModalProps> = ({ job, pipeline, isCompletingDisabled }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined);
    const [selectedTask, setSelectedTask] = useState<string | undefined>(undefined);
    const [isEligible, setIsEligible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isReassignmentChecked, setIsReassignmentChecked] = useState(false);


    const { data: tasks } = useGetTasksQuery({ workflow: pipeline.workflow });
    const { data: users } = useGetUsersQuery({}, {skip: !isUserAnnotationManager()});
    const { data: groups } = useGetGroupsQuery({}, {skip: !isUserAnnotationManager()});
    const { data: task } = useGetTaskQuery({ name: job.task }, { skip: !job.task });
    const { data: prevJob } = useGetJobQuery({ id: job.previous_job }, { skip: !job.previous_job });
    const { data: prevTask } = useGetTaskQuery({ name: task?.previous_task }, { skip: !task?.previous_task });

    const [completeJob, { isLoading: isCompletingJob }] = useCompleteJobMutation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isReassignmentChecked) return;
        if (prevJob) {
            setSelectedUser(prevJob.username);
        }
        if (prevTask) {
            setSelectedTask(prevTask.name);
        }
    }, [prevJob, prevTask, isReassignmentChecked]);

    useEffect(() => {
        if (selectedTask && selectedUser && tasks && users && groups) {
            const taskDetails = tasks.find(t => t.name === selectedTask);
            const userDetails = users.find(u => u.username === selectedUser);
            const isUserEligible = userDetails?.groups.some(groupId => groupId === taskDetails?.eligible_user_group) ?? false;
            setIsEligible(isUserEligible);

            if (!isUserEligible) {
                const eligibleGroupName = groups.find(g => g.id === taskDetails?.eligible_user_group)?.name;
                const userGroupNames = userDetails?.groups.map(groupId => groups.find(g => g.id === groupId)?.name).join(', ');
                setErrorMessage(`Selected user is not eligible for this task. Eligible group: ${eligibleGroupName}. User's groups: ${userGroupNames}`);
            } else {
                setErrorMessage(null);
            }
        }
    }, [selectedTask, selectedUser, tasks, users, groups]);

    const showModal = () => {
        setIsVisible(true);
    };

    const handleCompleteAndReassign = async () => {
        // Use the mutation to complete the job
        try {
            const response = await completeJob({
                id: job.id,
                username: selectedUser,
                taskId: selectedTask
            }).unwrap();

            setIsVisible(false);
            navigate('/jobs');
        } catch (error) {
            console.error('Failed to complete job:', error);
            setErrorMessage('Failed to complete the job.');
        }
    };


    const handleCancel = () => {
        setIsVisible(false);
    };

    const handleUserChange = (value: string) => {
        setSelectedUser(value);
    };

    const handleTaskChange = (value: string) => {
        setSelectedTask(value);
    };

    const renderModal = () => {
        if (isUserAnnotationManager()) {
            return (
                <Modal
                    title="Complete and re-assign Job"
                    open={isVisible}
                    onOk={handleCompleteAndReassign}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" onClick={handleCompleteAndReassign} disabled={isReassignmentChecked && !isEligible}>
                            Complete
                        </Button>,
                    ]}
                >
                    <Checkbox checked={isReassignmentChecked} onChange={(e) => setIsReassignmentChecked(e.target.checked)} style={{margin: '10px'}}>
                        Directly assign job to someone else
                    </Checkbox>
                    {isReassignmentChecked && (
                        <>
                            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}

                            <p>Select a task (by default, the task that comes before this review task is selected):</p>
                            <Select
                                value={selectedTask}
                                placeholder="Select a task"
                                style={{ width: 200 }}
                                onChange={handleTaskChange}
                            >
                                {tasks?.map(task => (
                                    <Option key={task.name} value={task.name}>{task.name}</Option>
                                ))}
                            </Select>
                            <p>Select a user to re-assign the work to (by default, the previous user is selected):</p>
                            <Select
                                value={selectedUser}
                                placeholder="Select a user"
                                style={{ width: 200 }}
                                onChange={handleUserChange}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {users?.map(user => (
                                    <Option key={user.username} value={user.username}>{user.username}</Option>
                                ))}
                            </Select>
                        </>
                    )}
                </Modal>
            )
        }
        return (
            <Modal
                title="Complete Job"
                okText="Yes"
                okType="primary"
                cancelText="Cancel"
                open={isVisible}
                onOk={handleCompleteAndReassign}
                onCancel={() => setIsVisible(false)}
            >
                Are you sure you want to complete this Job?
            </Modal>
        )
    }

    return (
        <>
            <Button
                className="job-complete-button"
                type="primary"
                icon={<CheckOutlined />}
                onClick={showModal}
                disabled={isCompletingDisabled}
                loading={isCompletingJob}
            >
                Complete
            </Button>
            {renderModal()}
        </>
    );
};

export default CompleteReassignJobModal;