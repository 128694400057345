import React, { useState } from 'react';
import { Table, Spin, Alert, Select, Button, Row, Col, Space, Typography, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
    useGetOutputJobsQuery,
    GetOutputJobsApiArg,
    AnnotationOutputSchema,
    useGetTasksQuery,
    useGetLabsQuery,
    useGetOrgansQuery,
    useGetStainingsQuery,
    useGetScannersQuery,
    useLazyGetOutputJobsCsvQuery,
    useGetClassificationScenariosQuery,
    useGetUsersQuery,
    useGetBacklogsQuery,
    useGetWorkflowsQuery
} from 'redux/slices/imageServerApi';
import { CheckCircleTwoTone, CloseCircleTwoTone, DotChartOutlined, DownloadOutlined, FileImageOutlined, ReloadOutlined } from '@ant-design/icons';
import { useFilters } from 'redux/hooks';

const { Text } = Typography;

interface JobOutputTableProps { }

type QueryArgs = GetOutputJobsApiArg;

const JobOutputTable: React.FC<JobOutputTableProps> = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize] = useState<number>(100);
    const [filters, setFilters] = useFilters<QueryArgs>();
    const [selectedColumns, setSelectedColumns] = useState<string[]>([
        'id', 'imageName', 'workflow', 'task', 'classificationScenario', 'backlogId', 'numAnnotations', 'lab', 'staining', 'organ', 'scanner', 'reviewed', 'qaQuality', 'datasetPartition', 'exclude', 'actions'
    ]);

    // Fetch data based on filters
    const { data, error, isLoading, isFetching, refetch } = useGetOutputJobsQuery({ ...filters, page: currentPage });
    const [csvExportTrigger, { isLoading: isCsvExportLoading }] = useLazyGetOutputJobsCsvQuery();

    const { data: tasks } = useGetTasksQuery({});
    const { data: backlogs } = useGetBacklogsQuery();
    const { data: labs } = useGetLabsQuery();
    const { data: organs } = useGetOrgansQuery();
    const { data: stainings } = useGetStainingsQuery();
    const { data: scanners } = useGetScannersQuery();
    const { data: workflows } = useGetWorkflowsQuery();
    const { data: classScenarios } = useGetClassificationScenariosQuery();
    const { data: annotators } = useGetUsersQuery({ group: 'Annotator' });
    const { data: pathologists } = useGetUsersQuery({ group: 'Pathologist' });

    const columns: ColumnsType<AnnotationOutputSchema> = [
        {
            title: 'Job ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Image',
            dataIndex: ['image_name'],
            key: 'imageName',
        },
        {
            title: 'Case',
            dataIndex: ['case'],
            key: 'case',
        },
        {
            title: 'Workflow',
            dataIndex: 'workflow',
            key: 'workflow',
            filters: workflows?.map(w => ({ text: w.name, value: w.name })) ?? [],
            filteredValue: filters['workflow'],
            filterMultiple: false,
        },
        {
            title: 'Task',
            dataIndex: 'task',
            key: 'task',
            filters: tasks?.map(t => ({ text: t.name, value: t.name })) ?? [],
            filteredValue: filters['task'],
            filterSearch: true,
            filterMultiple: true,
        },
        {
            title: 'Completed at',
            dataIndex: 'completed_at',
            key: 'completedAt'
        },
        {
            title: 'Class Scenario',
            dataIndex: 'classification_scenario',
            key: 'classificationScenario',
            filters: classScenarios?.map(cs => ({ text: cs.name, value: cs.id })),
            filteredValue: filters.classificationScenario,
            filterSearch: true,
            filterMultiple: true
        },
        {
            title: 'Pathologist',
            dataIndex: 'pathologist',
            key: 'pathologist',
            filters: pathologists?.map(p => ({ text: p.username, value: p.username })),
            filteredValue: filters.pathologist,
            filterSearch: true,
        },
        {
            title: 'Annotator',
            dataIndex: 'annotator',
            key: 'annotator',
            filters: annotators?.map(a => ({ text: a.username, value: a.username })),
            filteredValue: filters.annotator,
            filterSearch: true,
        },
        {
            title: 'ROI Type',
            dataIndex: 'roi_type',
            key: 'roiType'
        },
        {
            title: '# Annotations',
            dataIndex: 'num_annotations',
            key: 'numAnnotations'
        },
        {
            title: 'Lab',
            dataIndex: ['lab'],
            key: 'lab',
            filters: labs?.map(l => ({ text: l.name, value: l.id })),
            filteredValue: filters.lab,
            filterSearch: true,
            filterMultiple: true,
        },
        {
            title: 'Staining',
            dataIndex: ['staining'],
            key: 'staining',
            filters: stainings?.map(s => ({ text: s.name, value: s.id })),
            filteredValue: filters.staining,
            filterSearch: true,
            filterMultiple: true,
        },
        {
            title: 'Organ',
            dataIndex: ['organ'],
            key: 'organ',
            filters: organs?.map(o => ({ text: o.name, value: o.id })),
            filteredValue: filters.organ,
            filterSearch: true,
            filterMultiple: true,
        },
        {
            title: 'Scanner',
            dataIndex: ['scanner'],
            key: 'scanner',
            filters: scanners?.map(s => ({ text: s.name, value: s.id })),
            filteredValue: filters.scanner,
            filterSearch: true,
            filterMultiple: true,
        },
        {
            title: 'MPP',
            dataIndex: 'microns_per_pixel',
            key: 'micronsPerPixel'
        },
        {
            title: 'File Extension',
            dataIndex: 'file_extension',
            key: 'fileExtension'
        },
        {
            title: 'Mindpeak Products',
            dataIndex: 'mindpeak_products',
            key: 'mindpeakProducts'
        },
        {
            title: 'Significant Characteristics',
            dataIndex: 'significant_characteristics',
            key: 'significantCharacteristics'
        },
        {
            title: 'Tumor Type',
            dataIndex: 'tumor_type',
            key: 'tumorType'
        },
        {
            title: 'Tumor Stage',
            dataIndex: 'tumor_stage',
            key: 'tumorStage'
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes'
        },
        {
            title: 'Diagnosis',
            dataIndex: 'diagnosis',
            key: 'diagnosis'
        },
        {
            title: 'All reviewed?',
            dataIndex: ['reviewed'],
            key: 'reviewed',
            filters: [{ text: 'True', value: true }, { text: 'False', value: false }],
            filteredValue: filters.reviewed !== undefined ? [filters.reviewed] : undefined,
            filterMultiple: false,
            render: (value) => value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />
        },
        {
            title: 'QA Quality',
            dataIndex: ['qa_quality'],
            key: 'qaQuality',
            filters: ['excellent', 'good', 'bad', 'very_bad', 'exclude'].map(s => ({ text: s, value: s })),
            filteredValue: filters.qaQuality,
            filterMultiple: true,
        },
        {
            title: 'Backlog',
            dataIndex: ['backlog_id'],
            key: 'backlogId',
            filters: backlogs?.map(b => ({ text: b.name, value: b.id })),
            filteredValue: filters.backlogId,
            filterMultiple: true,
            render: (value) => backlogs?.find(b => b.id === value)?.name
        },
        {
            title: 'Dataset Partition',
            dataIndex: 'dataset_partition',
            key: 'datasetPartition',
            filters: ['train', 'test', 'unassigned', 'validation', 'train_validation', 'generalisation'].map(t => ({ text: t, value: t })),
            filteredValue: filters.datasetPartition,
            filterMultiple: true,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment'
        },
        {
            title: 'Exclude',
            dataIndex: 'exclude',
            key: 'exclude',
            filters: [{ text: 'True', value: true }, { text: 'False', value: false }],
            filteredValue: filters.exclude !== undefined ? [filters.exclude] : undefined,
            filterMultiple: false,
            render: (value) => value ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="red" />
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => renderRowActions(record)
        }
    ];

    const renderRowActions = (record: AnnotationOutputSchema) => {
        return (
            <Space>
                <Tooltip title="Image QA">
                    <Button type="default" icon={<FileImageOutlined />} href={`/images/${record.image_id}`} />
                </Tooltip>
                <Tooltip title="Annotation QA">
                    <Button type="default" icon={<DotChartOutlined />} href={`/jobs/${record.id}`} />
                </Tooltip>
            </Space>
        )
    }

    const filteredColumns = columns.filter(col => selectedColumns.includes(col.key as string));

    const handleColumnSelectionChange = (value: string[]) => {
        setSelectedColumns(value);
    };

    const handleExportCSV = async () => {
        try {
            const data = await csvExportTrigger(filters).unwrap();

            const blob = new Blob([data], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;

            const now = new Date();
            const isoString = now.toISOString().replace(/[:\-]/g, "").replace("T", "_").slice(0, 15);
            const fileTypeExtension = blob.type.split("/")[1];
            a.download = `annotation-info_${isoString}.${fileTypeExtension}`;

            document.body.appendChild(a);
            a.click();

            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error downloading the CSV export data:", error);
        }
    };

    if (error) {
        return (
            <Alert
                message="Error"
                description="There was an error fetching annotation jobs."
                type="error"
                showIcon
            />
        );
    }

    const items = data ? data.items : [];
    const totalItems = data ? data.count : 0;

    const handleTableChange = (pagination: any, filters: any) => {
        setCurrentPage(pagination.current);
        setFilters(filters);
        refetch();
    };

    const handleResetFilters = () => {
        setFilters({});
        refetch();
    };

    return (
        <Spin tip="Loading Annotation Outputs..." spinning={isLoading || isFetching}>
            <Row style={{ padding: '8px' }}>
                <Col span={20}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Text style={{ marginRight: '8px' }}>Columns to show: </Text>
                        <Select
                            mode="multiple"
                            placeholder="Columns to display"
                            style={{ flex: 1 }}
                            value={selectedColumns}
                            onChange={handleColumnSelectionChange}
                        >
                            {columns.map(col => (
                                <Select.Option key={col.key as string} value={col.key as string}>
                                    {col.title}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                </Col>
                <Col span={4} style={{ textAlign: 'right' }}>
                    <Button type='dashed' icon={<ReloadOutlined />} onClick={handleResetFilters}>Reset Filters</Button>
                    <Button type='primary' icon={<DownloadOutlined />} onClick={handleExportCSV} loading={isCsvExportLoading} style={{ marginLeft: '8px' }}>Download CSV</Button>
                </Col>
            </Row>
            <Table<AnnotationOutputSchema>
                dataSource={items}
                columns={filteredColumns}
                rowKey={(record) => record.id}
                pagination={{ current: currentPage, pageSize: pageSize, total: totalItems, showTotal: total => `${total} outputs` }}
                onChange={handleTableChange}
                sticky
            />
        </Spin>
    );
};

export default JobOutputTable;